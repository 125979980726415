import { Button, Col, Input, Label, Row } from "reactstrap";
import { Tooltip } from "@nextui-org/react";
import colors from "../../../../configs/Colors.config";
import { ToastContainer, toast } from "react-toastify";
import Block from "../../../../components/Block";
import { useState } from "react";
import DominosServices from "../../../../Services/Dominos.service";
import { IconButton } from "@mui/material";
import { Cached } from "@mui/icons-material";
import { useEffect } from "react";

const IngredientsAdmin = ({ collapsed }) => {

    const [dominosOriginal, setDominosOriginal] = useState(DominosServices.getDominosPricesStructure(1));
    const [dominosDominator, setDominosDominator] = useState(DominosServices.getDominosPricesStructure(2));
    const [dominosCrunchy, setDominosCrunchy] = useState(DominosServices.getDominosPricesStructure(3));
    const [dominosCheeseShore, setDominosCheeseShore] = useState(DominosServices.getDominosPricesStructure(4));
    const [dominosFrypan, setDominosFrypan] = useState(DominosServices.getDominosPricesStructure(5));
    const [dominosItalian, setDominosItalian] = useState(DominosServices.getDominosPricesStructure(6));

    const onChangePriceDominosOriginal = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosOriginal({
                ...dominosOriginal,
                [name]: value
            });

        }
    }
    const onChangePriceDominosDominator = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosDominator({
                ...dominosDominator,
                [name]: value
            });

        }
    }
    const onChangePriceDominosCrunchy = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosCrunchy({
                ...dominosCrunchy,
                [name]: value
            });

        }
    }
    const onChangePriceDominosCheeseShore = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosCheeseShore({
                ...dominosCheeseShore,
                [name]: value
            });

        }
    }
    const onChangePriceDominosFrypan = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosFrypan({
                ...dominosFrypan,
                [name]: value
            });

        }
    }
    const onChangePriceDominosItalian = ({ target }) => {
        let { name, value } = target;
        if (value.length === 0) {
            value = 0;
        } else {
            if (value.charAt(0) === "0") {
                value = value.slice(1);
            }
        }
        if (/^\d+$/.test(value)) {
            setDominosItalian({
                ...dominosItalian,
                [name]: value
            });

        }
    }

    useEffect(() => {
        getPricesDominos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dominosPriceSave = async (dominos) => {

        console.table(dominos)
        let IdToast = toast.loading("Guardando precios...");
        await DominosServices.dominosSavePrecies(dominos);
        await toast.update(IdToast, {
            render: 'Precios actualizado',
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            closeButton:true,
        })
    }

    const getPricesDominos = async () => {
        let response = await DominosServices.getDominosPriceList(1);
        let price = response.price;
        setDominosOriginal(price);

        response = await DominosServices.getDominosPriceList(2);
        price = response.price;
        setDominosDominator(price);

        response = await DominosServices.getDominosPriceList(3);
        price = response.price;
        setDominosCrunchy(price);

        response = await DominosServices.getDominosPriceList(4);
        price = response.price;
        setDominosCheeseShore(price);

        response = await DominosServices.getDominosPriceList(5);
        price = response.price;
        setDominosFrypan(price);

        response = await DominosServices.getDominosPriceList(6);
        price = response.price;
        setDominosItalian(price);
    }

    return (
        <div
            style={{
                // // width: "100%",
                marginLeft: collapsed ? 80 : 250,
                marginRight: 0,
                height: "100vh",
                marginTop: -25,
                padding: 10,
                fontFamily: "Pizza Press Fill",
            }}
        >
            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div
                style={{
                    backgroundColor: colors.blanco,
                    height: "97vh",
                    overflowY: "scroll",
                    borderRadius: 10
                }}
            >
                <Block height={10} backgroundColor={colors.blanco} style={{ borderRadius: "10px 10px 0px 0px", }} />
                <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blanco, }} >
                    <Col
                        style={{
                            marginRight: 10,
                            textAlign: "right"
                        }}
                    >
                        <Tooltip
                            content="Actualizar precios"
                            style={{
                                backgroundColor: colors.azul_1,
                                color: colors.blanco,
                                fontWeight: "bold",
                                fontFamily: "Pizza Press Fill",

                            }}
                        >
                            <IconButton
                                style={{
                                    backgroundColor: colors.rojo,
                                    color: colors.blanco,
                                    marginTop: 20,
                                }}
                                onClick={async () => {
                                    let IdToast = await toast.loading("Obteniendo Precios...");
                                    await getPricesDominos();
                                    await toast.update(IdToast, {
                                        render: 'Precios pbtenidos',
                                        isLoading: false,
                                        type: toast.TYPE.SUCCESS,
                                        autoClose: 3000,
                                        closeButton:true,
                                    });
                                }}
                            >
                                <Cached />
                            </IconButton>
                        </Tooltip>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blanco, padding: 20 }} >

                    <Col style={{ marginRight: 10 }} >
                        <Row style={{ marginRight: 0, marginLeft: 0, }} >
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        original
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        original
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col  >
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.priceBasicMedium}
                                            name={"priceBasicMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.priceBasicBig}
                                            name={"priceBasicBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.priceFavoriteMedium}
                                            name={"priceFavoriteMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.priceFavoriteBig}
                                            name={"priceFavoriteBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.pricePremiumMedium}
                                            name={"pricePremiumMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosOriginal.pricePremiumBig}
                                            name={"pricePremiumBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosOriginal);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosOriginal(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosOriginal);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginLeft: 10 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "40%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        DOMINATOR
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        DOMINATOR
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosDominator.priceBasicBigExtra}
                                            name={"priceBasicBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosDominator);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosDominator(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosDominator.priceFavoriteBigExtra}
                                            name={"priceFavoriteBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosDominator);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosDominator(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosDominator.pricePremiumBigExtra}
                                            name={"pricePremiumBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosDominator);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosDominator(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosDominator);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blanco, padding: 20 }} >

                    <Col style={{ marginRight: 10 }} >
                        <Row style={{ marginRight: 0, marginLeft: 0, }} >
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                        marginTop: 36
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        crunchy
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        crunchy
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col  >
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCrunchy.priceBasicBig}
                                            name={"priceBasicBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCrunchy);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCrunchy(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCrunchy.priceFavoriteBig}
                                            name={"priceFavoriteBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCrunchy);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCrunchy(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCrunchy.pricePremiumBig}
                                            name={"pricePremiumBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCrunchy);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCrunchy(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosCrunchy);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginLeft: 10 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "45%",
                                        height: 50,
                                        position: "relative",
                                        zIndex: 1
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        orilla rellena
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        orilla rellena
                                    </Label>
                                </div>
                                <Label
                                    style={{
                                        zIndex: 2,
                                        fontFamily: "Agbalumo",
                                        marginTop: -50,
                                        fontSize: 36,
                                        position: "relative",
                                        WebkitTextStroke: `1px #0C3360`,
                                        color: "#FFFFFF",
                                        fontWeight: "bold"
                                    }}
                                >
                                    de Queso
                                </Label>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.priceBasicMedium}
                                            name={"priceBasicMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.priceBasicBig}
                                            name={"priceBasicBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.priceFavoriteMedium}
                                            name={"priceFavoriteMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.priceFavoriteBig}
                                            name={"priceFavoriteBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.pricePremiumMedium}
                                            name={"pricePremiumMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosCheeseShore.pricePremiumBig}
                                            name={"pricePremiumBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosCheeseShore);
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosCheeseShore(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosCheeseShore);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                    }}
                >
                    <Col style={{ marginRight: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "60%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        pizza de sartén
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        pizza de sartén
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.priceBasicMedium}
                                            name={"priceBasicMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.priceBasicBig}
                                            name={"priceBasicBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.priceFavoriteMedium}
                                            name={"priceFavoriteMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.priceFavoriteBig}
                                            name={"priceFavoriteBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.pricePremiumMedium}
                                            name={"pricePremiumMedium"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosFrypan.pricePremiumBig}
                                            name={"pricePremiumBig"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosFrypan);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosFrypan(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosFrypan);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginLeft: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                        zIndex: 1
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        italiana
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        italiana
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosItalian.priceBasicBigExtra}
                                            name={"priceBasicBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosItalian);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosItalian(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosItalian.priceFavoriteBigExtra}
                                            name={"priceFavoriteBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosItalian);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosItalian(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        <Input
                                            style={{ textAlign: "center", backgroundColor: "transparent", width: "100%", height: "100%", fontSize: 30, color: colors.blanco, borderColor: "transparent", boxShadow: "none" }}
                                            value={dominosItalian.pricePremiumBigExtra}
                                            name={"pricePremiumBigExtra"}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") dominosPriceSave(dominosItalian);;
                                            }}
                                            onChange={(event) => {
                                                onChangePriceDominosItalian(event);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col
                                style={{ textAlign: "right", marginTop: 10 }}
                            >
                                <Button
                                    style={{
                                        marginRight: -15,
                                        boxShadow: "none",
                                        backgroundColor: colors.rojo,
                                        borderColor: colors.rojo,
                                        color: colors.blanco,
                                        fontWeight: "bold"
                                    }}
                                    onClick={() => {
                                        dominosPriceSave(dominosItalian);
                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Block height={30} />
            </div>

        </div>
    )

}

export default IngredientsAdmin;