
import { useEffect, useState } from "react";
import DeviceConfig from "../configs/Device.config";
import MenuDominosDesktop from "./menus/MenuDominosDesktop";
import MenuDominosMovile from "./menus/MenuDominosMovile";


const MenuDominos = () => {

    const [device, setDevice] = useState(DeviceConfig.getStructureDevice());

    useEffect(() => {
        loadScreen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadScreen = async () => setDevice(DeviceConfig.getDevice());



    return (device.isDesktopDevice ? <MenuDominosDesktop /> : <MenuDominosMovile />);

}

export default MenuDominos;