/** Importaciones de componentes y clases */
import { Cached, Close, Done, WhatsApp } from "@mui/icons-material";
import { Col, Row, Label, Button, Modal, ModalHeader, ModalBody, DropdownItem } from "reactstrap";
import StoreService from "../Services/Store.service";
import Table from "react-data-table-component";
import colors from "../configs/Colors.config";
import { useParams } from "react-router-dom";
import Device from "../configs/Device.config";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import TimeTool from "../Tools/Time.tool";
import Block from "../components/Block";
import Iframe from 'react-iframe';
import Swal from "sweetalert2";
import { Fade, Zoom } from "react-reveal";

/** Importación de imágenes */
import pizza from "../assets/images/resources/pizza.svg";
import logoVerticalAzul from "../assets/images/logo/logoVerticalAzul.png";
import time from "../assets/images/resources/time.svg"
import mapa from "../assets/images/resources/mapa.svg"
import Text from "../components/Text";
import NumberTool from "../Tools/Number.tool";
import { toast } from "react-toastify";


/******************************************/

const Store = () => {
    let [isHoverButtonWhatsApp, setIsHoverButtonWhatsApp] = useState(false);
    let [isHoverLabel, setIsHoverLabel] = useState(false);
    let { myUdn } = useParams();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isZooming, setIsZooming] = useState(false);
    const [device, setDevice] = useState({ isDesktopDevice: false, isMobileDevice: false, });
    const [store, setStore] = useState(StoreService.getStoreStructure());
    const [storeList, setStoreList] = useState([]);
    const [isPressingButtonCall, setIsPressingButtonCall] = useState(false);
    const [isPressingButtonCallSeeMenu, setIsPressingButtonCallSeeMenu] = useState(false);
    const columnTableStore = [
        {
            name: <p style={{ fontSize: 24, fontWeight: "bold", color: colors.azul_1, fontFamily: "Pizza Press Fill" }}>Nombre</p>,
            width: device.isMobileDevice ? "200px" : "",
            selector: row =>
                <label
                    style={{ fontSize: 18, color: colors.azul_1, fontFamily: "One Dot", cursor: "pointer" }}
                    onClick={() => {
                        getStoreByUDN(row.UDN);
                        setIsOpenModal(false);
                        TimeTool.sleep(0.1).then(() => {
                            window.location.href = "#"
                            TimeTool.sleep(0.1).then(() => {
                                window.location.href = "#"
                            });
                        });
                    }}
                >
                    {row.nameStore}
                </label>,
        },
        {
            name: <p style={{ fontSize: 24, fontWeight: "bold", color: colors.azul_1, fontFamily: "Pizza Press Fill" }}>Ordena</p>,
            width: device.isMobileDevice ? "200px" : "",
            selector: row =>
                <Button
                    style={{
                        backgroundColor: row.phone.length === 0 ? colors.rojo : colors.azul_1,
                        boxShadow: "none",
                        width: row.phone.length === 0 ? 126 : ""
                    }}
                    onClick={() => {
                        if (row.phone.length > 0) {
                            window.location.href = `tel:${row.phone}`;
                        }

                    }}
                >
                    <Text
                        style={{
                            cursor: "pointer"
                        }}
                        text={
                            row.phone.length === 0 ?
                                "Visítanos"
                                :
                                NumberTool.formatPhoneNumber(row.phone)
                        }
                    />
                </Button>,
            sortable: true,
        },
        device.isDesktopDevice ?
            {
                name: <p style={{ fontSize: 24, fontWeight: "bold", color: colors.azul_1, fontFamily: "Pizza Press Fill" }}>Ordena</p>,
                selector: row =>
                    <IconButton
                        style={{
                            backgroundColor: colors.blanco,
                            color: colors.azul_1
                        }}
                        onClick={() => {
                            getStoreByUDN(row.UDN);
                            setIsOpenModal(false);
                        }}
                    >
                        <Done />
                    </IconButton>,
                sortable: true,
            }
            :
            {}
    ]

    const getStoreByUDN = async (UDN) => {
        let response = await StoreService.getStoreByUDN(UDN);
        let { error } = response
        if (error) {
            Swal.fire({
                icon: 'warning',
                title: 'Unidad no encontrada',
                text: `No se encontró una unidad con este UDN:${UDN}`,
                confirmButtonColor: colors.azul_1,
                //timer: 15000,
                timerProgressBar: true,
                footer: "<label>Serás redireccionado a una lista con todas las unidades disponibles</label>"
            }).then(() => {
                window.location.href = "/";
            });
        } else {
            const { store } = response;
            setStore(store);
        }

    };

    const getListStoreByIdState = async () => {
        const response = await StoreService.getStoreListByState(store.IdState);
        console.log(response);
        const { error } = response;
        if (!error) {
            const { storeList } = response;
            console.log(storeList);
            setStoreList(storeList);
        } else {
            toast.error("No se pudieron obtener las unidades");
        }
    }

    useEffect(() => {
        loadAllCommonest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadAllCommonest = async () => {
        console.clear();
        let device = await Device.getDevice();
        await setDevice(device);
        await getStoreByUDN(myUdn);
        await TimeTool.sleep(0.3);

        const timer = setTimeout(() => {
            setIsZooming(true);
        }, 1200);


        return () => clearTimeout(timer);
    }

    const Menu = ({ color }) => {
        return (
            device.isMobileDevice ?
                <>
                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                        }}
                    >
                        <Col style={{ textAlign: "right", position: "fixed", bottom: 55 }} >
                            <IconButton
                                style={{
                                    borderRadius: 50,
                                    backgroundColor: "#25D366",
                                    height: 50,
                                    width: 50,
                                    transition: 'transform 0.3s ease',
                                    transform: isHoverButtonWhatsApp ? 'scale(1.1)' : 'scale(1)',
                                }}
                                onClick={() => {
                                    window.location.href = `https://wa.me/+525511866666`
                                }}
                            >
                                <WhatsApp style={{ height: 30, width: 30, color: "#FFFFFF", }} />
                            </IconButton>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            bottom: 0,
                            position: 'fixed',
                            background: color,
                            height: 45,
                            width: "100%",
                            border: `solid 1px ${color}`,
                            fontSize: 24,
                            borderRadius: "20px 20px 0px 0px"
                        }}
                    >
                        <Col>
                            <Button
                                style={{
                                    width: "33%",
                                    backgroundColor: color,
                                    borderColor: color,
                                    boxShadow: "none",
                                    height: "100%",
                                    borderRadius: "30px 30px 0px 0px",
                                    fontSize: 24,
                                }}
                                onClick={() => {
                                    window.location.href = "#"
                                }}
                            >
                                <Text text={"Inicio"} />
                            </Button>
                            <Button
                                style={{
                                    width: "33%",
                                    backgroundColor: color,
                                    borderColor: color,
                                    boxShadow: "none",
                                    height: "100%",
                                    borderRadius: "30px 30px 0px 0px",

                                    fontSize: 24,
                                }}
                                onClick={() => {
                                    window.location.href = "#mapa"
                                }}
                            >
                                <Text text={"Mapa"} />
                            </Button>
                            <Button
                                style={{
                                    width: "33%",
                                    backgroundColor: color,
                                    borderColor: color,
                                    boxShadow: "none",
                                    height: "100%",
                                    borderRadius: "30px 30px 0px 0px",

                                    fontSize: 24,
                                }}
                                onClick={() => {
                                    window.location.href = "#horario"
                                }}
                            >
                                <Text text={"Horario"} />
                            </Button>
                        </Col>
                    </Row>
                </>
                :
                <>
                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                        }}
                    >
                        <Col style={{ textAlign: "right", position: "fixed", bottom: 80 }} >
                            <IconButton
                                style={{
                                    borderRadius: 50,
                                    backgroundColor: "#25D366",
                                    height: 70,
                                    width: 70,
                                    transition: 'transform 0.3s ease',
                                    transform: isHoverButtonWhatsApp ? 'scale(1.1)' : 'scale(1)',
                                }}
                                onMouseEnter={() => setIsHoverButtonWhatsApp(true)}
                                onMouseLeave={() => setIsHoverButtonWhatsApp(false)}
                                onClick={() => {
                                    window.open(`https://web.whatsapp.com/send?phone=+525511866666`);
                                }}
                            >
                                <WhatsApp style={{ height: 50, width: 50, color: "#FFFFFF", }} />
                            </IconButton>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                        }}
                    >
                        <Col style={{ textAlign: "right", position: "fixed", bottom: 10 }} >
                            <Button
                                style={{
                                    backgroundColor: isPressingButtonCallSeeMenu ? colors.azul_1 : colors.rojo,
                                    borderColor: isPressingButtonCallSeeMenu ? colors.azul_1 : colors.rojo,
                                    fontSize: 24,
                                    width: 300,
                                    marginRight: 10,

                                }}
                                onMouseEnter={() => setIsPressingButtonCallSeeMenu(true)}
                                onMouseLeave={() => setIsPressingButtonCallSeeMenu(false)}
                                onClick={() => {
                                    window.open('/menu');
                                }}
                            >
                                <label
                                    style={{
                                        transform: isPressingButtonCallSeeMenu ? 'translateY(-2px) translateX(2px)' : '',
                                        transition: 'background-color 0.9s, transform 0.9s ease-in-out',
                                        backgroundColor: 'transparent',
                                        position: "absolute",
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        color: "#00283F",
                                    }}
                                >
                                    Ver Menu
                                </label>
                                <label
                                    style={{
                                        transform: isPressingButtonCallSeeMenu ? 'translateY(-2px) translateX(2px)' : '',
                                        transition: 'background-color 0.9s, transform 0.9s ease-in-out',
                                        backgroundColor: 'transparent',
                                        fontFamily: "Pizza Press Fill",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Ver Menu
                                </label>
                            </Button>
                        </Col>
                    </Row >
                </>
        );
    }

    return (
        <>

            <div
                style={{ fontFamily: "Pizza Press Fill" }}
            >
                {/*  */}
                <div
                    id=""
                    style={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: colors.blanco,
                        position: "relative",
                        zIndex: 2
                    }}
                >
                    <Block height={"8vh"} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <center>
                            <Fade top>
                                <img
                                    src={logoVerticalAzul}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        height: "25vh"
                                    }}
                                />
                            </Fade>
                        </center>
                    </Row>
                    <Block height={20} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <center>
                            {isZooming ?
                                <Zoom>
                                    <div
                                        style={{
                                            padding: 1,
                                            minHeight: '50px',
                                            background: colors.rojo,
                                            clipPath: 'polygon(100% 0, 95% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0)',
                                            textTransform: 'uppercase',
                                            color: colors.blanco,
                                            letterSpacing: '0.18em',
                                            fontSize: store.nameStore.split(" ").length <= 2 ? 32 : 20,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: device.isMobileDevice ? '90%' : "50%",
                                            maxWidth: '90%',
                                        }}
                                    >
                                        <p
                                            style={{
                                                lineHeight: 1.1,
                                                maxWidth: "90%",
                                                marginBottom: 1
                                            }}
                                        >
                                            {store.nameStore}
                                        </p>
                                    </div>
                                </Zoom>
                                :
                                <Block height={50} />
                            }
                        </center>
                    </Row>
                    <Block height={20} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <div style={{ lineHeight: '1', }} >
                            <center>
                                <Fade bottom >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: colors.azul_1,
                                            fontWeight: "bold"
                                        }}
                                    >
                                        DELIVERY
                                    </Label>

                                </Fade>

                            </center>
                        </div>
                    </Row>
                    <Block height={20} />
                    <div
                        style={{ marginRight: 0, marginLeft: 0 }}
                    >
                        <center>
                            <Fade bottom >
                                <Button
                                    style={{
                                        zIndex: 2,
                                        fontSize: 24,
                                        backgroundColor: isPressingButtonCall ? colors.rojo : colors.azul_1,
                                        color: colors.blanco,
                                        // marginTop: -15,
                                        width: device.isMobileDevice ? "70%" : "50%",
                                        boxShadow: "none",
                                        borderRadius: 100,
                                        fontFamily: "Pizza Press Fill",
                                        fontStyle: "normal"
                                    }}
                                    /** Click */
                                    onMouseEnter={() => {
                                        if (device.isDesktopDevice) {
                                            setIsPressingButtonCall(true);
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (device.isDesktopDevice) {
                                            setIsPressingButtonCall(false);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!(store.phone.length === 0)) {
                                            window.location.href = `tel:${store.phone}`;
                                        }
                                    }}
                                >
                                    <Text text={store.phone.length === 0 ? "Visítanos" : "Llámanos"} />
                                </Button>
                            </Fade>
                        </center>
                    </div>
                    <Block height={20} />
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <center>
                            <Fade bottom >
                                <img
                                    src={pizza}
                                    alt="No se pudo cargar la imagen"
                                    style={{
                                        zIndex: 0,
                                        height: device.isMobileDevice ? 200 : 250,
                                    }}
                                />
                            </Fade>
                            <Fade bottom>
                                <p
                                    style={{
                                        fontSize: 20,
                                        color: colors.azul_1,
                                        lineHeight: 1.1,
                                    }}
                                >
                                    {device.isMobileDevice ? " DEL MOSTRADOR A TU CASA" : "DEL MOSTRADOR HASTA LA COMODIDAD DE TU CASA"}
                                </p>
                            </Fade>
                        </center>

                    </Row>
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: device.isDesktopDevice ? -15 : -10
                        }}
                    >
                        <Fade bottom >
                            <center>
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderLeft: "10px solid transparent",
                                        borderRight: "10px solid transparent",
                                        borderBottom: `15px solid ${colors.rojo}`,
                                        transform: "rotate(180deg)",
                                        color: colors.blanco,
                                        display: "inline-block",
                                        margin: "5px",
                                    }}
                                >
                                </div>
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderLeft: "10px solid transparent",
                                        borderRight: "10px solid transparent",
                                        borderBottom: `15px solid ${colors.rojo}`,
                                        transform: "rotate(180deg)",
                                        color: colors.blanco,
                                        display: "inline-block",
                                        margin: "5px",
                                    }}
                                >
                                </div>
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderLeft: "10px solid transparent",
                                        borderRight: "10px solid transparent",
                                        borderBottom: `15px solid ${colors.rojo}`,
                                        transform: "rotate(180deg)",
                                        // position: "absolute",
                                        color: colors.blanco,
                                        display: "inline-block",
                                        margin: "5px",
                                    }}
                                >
                                </div>
                            </center>
                        </Fade>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center', // Centrar horizontalmente
                            alignItems: 'center', // Centrar verticalmente
                        }}
                    >
                        <div
                            style={{
                                width: 0,
                                height: 0,
                                borderLeft: "50px solid transparent",
                                borderRight: "50px solid transparent",
                                borderBottom: `30px solid ${colors.azul_1}`,
                                transform: "rotate(0deg)",
                                position: "absolute",
                                top: "100%",
                                color: colors.blanco,
                                marginTop: -29,
                            }}
                        >

                        </div>
                    </div>
                </div>
                {/* Mapa */}
                <div id="mapa" ></div>
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: colors.azul_1,
                        position: "fixed",
                        top: 0,
                        zIndex: 1,
                        padding: 0,
                    }}
                >
                    <Block height={"8vh"} />
                    <div
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            lineHeight: 1,
                        }}
                    >
                        <center>
                            <Text
                                text={"Ubicación"}
                                fontSize={40}
                            />
                        </center>
                    </div>
                    <div
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            textAlign: "center",
                            marginTop: 20
                        }}
                    >
                        <center>
                            {
                                store.urlGoogleMaps.length === 0 ?
                                    <img
                                        src={mapa}
                                        alt="No se pudo cargar la imagen"
                                        width="90%"
                                        height={device.isMobileDevice ? 250 : 450}
                                        styles={{
                                            borderRadius: 10
                                        }}
                                    />
                                    :
                                    <Iframe
                                        src={store.urlGoogleMaps}
                                        width="90%"
                                        height={device.isMobileDevice ? 250 : 350}
                                        styles={{
                                            borderRadius: 10
                                        }}
                                    />
                            }

                        </center>
                    </div>
                    <Block height={10} />
                    <div
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            textAlign: "center",
                        }}
                    >
                        <p
                            style={{
                                fontSize: device.isMobileDevice ? 18 : 24,
                                color: colors.blanco,
                                lineHeight: 1,
                                fontFamily: "One Dot",
                            }}
                        >
                            {store.address.length === 0 ? "Aun no tenemos un mapa para mostraste, estamos trabajando para darte la mejor experiencia, espéranos..." : store.direccion}
                        </p>
                    </div>
                    <Block height={device.isMobileDevice ? 70 : 0} />
                    <div
                        style={{
                            marginRight: 0,
                            marginLeft: 0,
                            textAlign: "center",
                            cursor: "pointer",
                        }}
                    >
                        <Label
                            style={{
                                fontSize: 30,
                                color: colors.blanco,
                                marginRight: 10,
                                cursor: "pointer",
                            }}
                            onMouseEnter={() => setIsPressingButtonCall(true)}
                            onMouseLeave={() => setIsPressingButtonCall(false)}
                            onClick={() => {
                                window.location.href = "/menu";
                            }}
                        >
                            Conoce nuestro menú <Text text={"Haz click aquí"} style={{ cursor: "pointer"}} />
                        </Label>
                        {/* <Button
                            style={{
                                backgroundColor: isPressingButtonCall ? colors.blanco : colors.rojo,
                                color: isPressingButtonCall ? colors.rojo : colors.blanco,
                                borderRadius: 50,
                                fontSize: 24,
                                zIndex: 4,
                                position: "relative"
                            }}
                            onMouseDown={() => setIsPressingButtonCall(true)}
                            onMouseUp={() => setIsPressingButtonCall(false)}
                            onMouseEnter={() => setIsPressingButtonCall(true)}
                            onMouseLeave={() => setIsPressingButtonCall(false)}
                            
                        >
                           
                        </Button> */}
                    </div>
                </div>

                {/* Horario */}
                <div style={{ width: "100%", height: "100vh", backgroundColor: colors.blanco, zIndex: 2, position: "static", }}  ></div>
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: colors.blanco,
                        zIndex: 2,
                        position: "relative",
                    }}
                >
                    <Block height={"8vh"} />
                    <div
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            textAlign: "center"
                        }}
                    >
                        <Label
                            style={{
                                fontSize: 40,
                                color: colors.negro,
                                fontFamily: "Pizza Press Fill"
                            }}
                        >
                            Nuestros Horarios
                        </Label>
                    </div>

                    <DropdownItem style={{ marginRight: "5%", marginLeft: "5%", }} divider />

                    <div
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                        }}
                    >
                        <Label
                            style={{
                                fontSize: 26,
                                color: colors.azul_1
                            }}
                        >
                            Domino's {store.nameStore}
                        </Label>
                    </div>
                    <div
                        style={{
                            lineHeight: 1.1,
                            color: colors.azul_1,
                            fontSize: 20,
                            marginRight: "5%",
                            marginLeft: "5%",
                        }}
                    >
                        <Label>
                            Visítanos de  {TimeTool.format12(store.timeOpening)} a {TimeTool.format12(store.timeClosing)} todos los dias
                        </Label>

                    </div>
                    <DropdownItem style={{ marginRight: "5%", marginLeft: "5%", }} divider />
                    <div
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            marginTop: 30
                        }}
                    >
                        {
                            device.isMobileDevice ?
                                <center>
                                    <Button
                                        style={{
                                            fontSize: 24,
                                            backgroundColor: isPressingButtonCall ? colors.rojo : colors.azul_1,
                                            color: colors.blanco,
                                            marginTop: -15,
                                            width: "100%",
                                            boxShadow: "none",
                                            borderRadius: 100,
                                            fontFamily: "Pizza Press Fill",
                                            fontStyle: "normal"
                                        }}
                                        onMouseDown={() => setIsPressingButtonCall(true)}
                                        onMouseUp={() => setIsPressingButtonCall(false)}
                                        onMouseEnter={() => setIsPressingButtonCall(true)}
                                        onMouseLeave={() => setIsPressingButtonCall(false)}
                                        onClick={() => {
                                            setIsOpenModal(true);
                                        }}
                                    >
                                        Buscar otra unidad
                                        {/* <Text text={"Buscar otra unidad"} /> */}
                                    </Button>
                                </center>
                                :
                                <Label
                                    style={{
                                        color: isHoverLabel ? colors.rojo : colors.azul_1,
                                        transition: 'font-size 0.3s ease-in-out ',
                                        fontSize: isHoverLabel ? 25 : 24,
                                        textDecoration: isHoverLabel ? 'underline' : '',
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={() => setIsHoverLabel(true)}
                                    onMouseLeave={() => setIsHoverLabel(false)}
                                    onClick={() => {
                                        setIsOpenModal(true);
                                    }}
                                >
                                    ¿Quieres ver la información de otra unidad?, has Click aquí
                                </Label>
                        }

                    </div>

                    <div>
                        <center>
                            <img
                                src={time}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    width: "80%",
                                    height: 320
                                }}
                            />
                        </center>
                    </div>
                    <Menu color={colors.rojo} />
                </div>

            </div >
            <div id="horario" ></div>


            <Modal
                isOpen={isOpenModal}
                size={"lg"}
                onOpened={() => {
                    getListStoreByIdState();
                }}
                style={{
                    maxWidth: device.isDesktopDevice ? "50%" : ""
                }}
            >
                <ModalHeader
                    close={
                        <Col
                            style={{
                                textAlign: "right"
                            }}
                        >
                            <IconButton
                                style={{ backgroundColor: colors.blanco, color: colors.azul_1, marginRight: 10 }}
                                onClick={() => {
                                    getListStoreByIdState();
                                }}
                            >
                                <Cached />
                            </IconButton>
                            <IconButton
                                style={{ backgroundColor: colors.blanco, color: colors.azul_1 }}
                                onClick={() => {
                                    setIsOpenModal(false);
                                    setStoreList([]);
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                    style={{
                        backgroundColor: colors.azul_1,
                        color: colors.blanco,
                        fontSize: 24
                    }}
                >
                    <Label style={{ marginTop: 5, fontFamily: "Pizza Press Fill" }} >
                        Unidades de Domino's GPM
                    </Label>
                </ModalHeader>
                <ModalBody
                    style={{
                        height: "80vh",
                        overflowY: "scroll",
                    }}
                >
                    <Table
                        columns={columnTableStore}
                        data={storeList}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default Store;