import { Label, Row, Col } from "reactstrap";
import Colors from "../configs/Colors.config";
import Block from "../components/Block";
import { useEffect, useState } from "react";
import Table from "react-data-table-component";
import { IconButton } from "@mui/material";
import colors from "../configs/Colors.config";
import Device from "../configs/Device.config";
import { Done } from "@mui/icons-material";
import StoreService from "../Services/Store.service";

/** Importación de imágenes */
import logoVerticalBlanco from "../assets/images/logo/logoVerticalBlanco.png";
import { toast } from "react-toastify";

const StoreList = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [device, setDevice] = useState({ isDesktopDevice: false, isMobileDevice: false, });
    const [storeList, setStoreList] = useState([]);
    const columnTableStore = [
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>UDN</p>,
            selector: row =>
                <label
                    style={{ fontWeight: "bold", fontSize: 18 }}
                >
                    {row.UDN}
                </label>,
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>Nombre</p>,
            selector: row =>
                <label
                    style={{ fontWeight: "bold", fontSize: 18 }}
                >
                    {row.nameStore}
                </label>,
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>Estado</p>,
            selector: row =>
                <label
                    style={{ fontWeight: "bold", fontSize: 18 }}
                >
                    {row.nameState}
                </label>,
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>Ciudad</p>,
            selector: row =>
                <label
                    style={{ fontWeight: "bold", fontSize: 18 }}
                >
                    {row.nameCity}
                </label>,
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>Estatus</p>,
            selector: row =>
                <label
                    style={{ fontWeight: "bold", fontSize: 18 }}
                >
                    {row.IdStatus === 1 ? "Abierto al publico" : "Cerrado temporalmente"}
                </label>,
        },
        {
            name: <p style={{ fontWeight: "bold", fontSize: 24 }}>Acciones</p>,
            selector: row =>
                <IconButton
                    style={{ fontWeight: "bold", color: colors.azul_1 }}
                    onClick={() => {
                        window.location.href = `/${row.UDN}`
                    }}
                >
                    <Done />
                </IconButton>,
        },
    ]

    useEffect(() => {

        /** Cargando datos de pantalla */
        console.clear();
        let device = Device.getDevice();
        setDevice(device);
        getStoreList();
        setIsLoad(false);

    }, []);

    const getStoreList = async () => {
        const response = await StoreService.getStoreList();

        const { error } = response;
        if (!error) {
            const { storeList } = response;
            setStoreList(storeList);
        } else {
            toast.error("No se pudieron obtener las unidades");
        }
    }

    return (

        isLoad ?
            <section style={{
                maxWidth: "100%",
                minHeight: "100vh",
                backgroundColor: Colors.azul_1,
                fontFamily: "Pizza Press Fill",
            }} >

            </section>
            :

            <section
                style={{
                    maxWidth: "100%",
                    minHeight: "auto",
                    backgroundColor: Colors.azul_1,
                    fontFamily: "Pizza Press Fill",
                }}

            >
                <Block height={device.isDesktopDevice ? "5vh" : 0} />
                <Row
                    style={{
                        marginRight: device.isDesktopDevice ? 50 : 0,
                        marginLeft: device.isDesktopDevice ? 50 : 0,
                        backgroundColor: colors.azul_2,
                        borderRadius: device.isDesktopDevice ? "10px 10px 0px 0px" : 0
                    }}
                >
                    <Block height={20} />
                    <Col>
                        <img
                            src={logoVerticalBlanco}
                            alt="No se pudo cargar la imagen"
                            style={{
                                height: 60,
                                marginLeft: 10
                            }}
                        />

                        <Label
                            style={{
                                color: colors.blanco,
                                fontSize: device.isDesktopDevice ? 36 : 28,
                                fontWeight: "bold",
                                marginLeft: device.isDesktopDevice ? 30 : 10,
                                marginTop: 5,

                            }}
                        >

                            Lista de Unidades
                        </Label>
                    </Col>
                    <Block height={20} />
                </Row>
                <Row
                    style={{
                        marginRight: device.isDesktopDevice ? 50 : 0,
                        marginLeft: device.isDesktopDevice ? 50 : 0,
                        backgroundColor: colors.blanco,
                        borderRadius: device.isDesktopDevice ? "0px 0px 10px 10px" : 0
                    }}
                >
                    <Block height={20} />
                    <Table
                        columns={columnTableStore}
                        data={storeList}
                    />
                </Row>
                <Block height={"5vh"} />
            </section>

    );

}

export default StoreList;