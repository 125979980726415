
/** Importaciones de clases y componentes */
import colors from "../../../configs/Colors.config";
import { Col, Label, Row, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Block from "../../../components/Block";
import { AccountCircle, Close, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { FormGroup, IconButton } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import TimeTool from "../../../Tools/Time.tool";
import ComponentTool from "../../../Tools/Component.tool";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../../Services/User.service";
import Cookies from 'universal-cookie';

/** Importaciones de imágenes */
import logoVerticalAzul from "../../../assets/images/logo/logoVerticalAzul.png";
import marketing from "../../../assets/images/svg/marketing.svg";
import logo from "../../resources/logo.png";
import "./../../style.css"

const cookies = new Cookies();

const MarketingLogin = () => {

    const [userData, setUserData] = useState({ username: "", password: "" });

    const [labelWarning, setLabelWarning] = useState("");
    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const onChangeUserData = ({ target }) => {
        const { name, value } = target;
        setUserData({
            ...userData,
            [name]: value
        });
    }

    useEffect(() => {
        loadPage();
        const favicon = document.createElement('link');
        favicon.rel = 'icon';
        favicon.type = 'image/x-icon';
        favicon.href = logo
        
        document.head.appendChild(favicon);
    }, []);

    const loadPage = () => {
        console.clear();
        ComponentTool.focus("username");
    }

    const validateUserData = () => {
        let response = true;
        if (userData.username.length === 0 && userData.password.length === 0) {
            response = false;
            setLabelWarning("Falta que escribas tu nombre de usuario y contraseña 🤔🖋️🔐");
        } else {
            if (userData.username.length === 0) {
                response = false;
                setLabelWarning("Falta que escribas tu nombre de usuario 🤔🖋️🔐");
            }
            if (userData.password.length === 0) {
                response = false;
                setLabelWarning("Falta que escribas tu contraseña 🤔🖋️🔐");
            }
        }

        return response
    }

    const userLogin = async () => {
        let response = validateUserData();
        if (!response) {
            toast.warning("No se inicio sesión");

        } else {
            let IdToast = toast.loading("Iniciando sesión");
            await TimeTool.sleep(1.5);
            response = await UserService.login(userData);
            const { error } = response;

            if (!error) {
                const { data } = response;
                if (!data.error) {
                    if (data.status === 200) {
                        toast.update(IdToast, {
                            render: 'Usuario logueado',
                            isLoading: false,
                            type: toast.TYPE.SUCCESS,
                            autoClose: 1100,
                        });
                        cookies.set('user', data.user, { path: '/' });
                        TimeTool.sleep(1.5).then(() => {
                            window.location.href = "/user/marketing/dashboard"
                        });
                    }
                    if (data.status === 404) {
                        toast.update(IdToast, {
                            render: 'Usuario no encontrado',
                            isLoading: false,
                            type: toast.TYPE.WARNING,
                            autoClose: 3000,
                        });
                        setLabelWarning("Usuario no encontrado o contraseña incorrecta 😕🔒🚫");
                    }

                } else {
                    toast.update(IdToast, {
                        render: 'Error',
                        isLoading: false,
                        type: toast.TYPE.ERROR, // Cambia el tipo de notificación a éxito
                        autoClose: 3000, // Establece la duración de la notificación en milisegundos (opcional)
                    });
                }

            } else {
                toast.update(IdToast, {
                    render: 'Error',
                    isLoading: false,
                    type: toast.TYPE.ERROR, // Cambia el tipo de notificación a éxito
                    autoClose: 3000, // Establece la duración de la notificación en milisegundos (opcional)
                });
            }
        }
    }

    return (
        <section
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "#F4F4F4"
            }}
        >
            <ToastContainer
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Block height={"10vh"} />
            <Row
                style={{
                    marginRight: "10%",
                    marginLeft: "10%",
                    width: "80%",
                    height: "80vh",
                    borderRadius: 10,
                    boxShadow: "-10px 0 10px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2)",
                    fontFamily: "Pizza Press Fill",
                }}
            >
                <Col
                    style={{
                        width: "50%",
                        minWidth: "50%",
                        maxWidth: "50%",
                        backgroundColor: colors.blanco,
                        borderRadius: "10px 0px 0px 10px",
                        color: colors.azul_2,
                    }}
                >
                    <Row style={{ marginTop: 30, width: "90%", marginLeft: "5%", marginRight: "5%" }} >
                        <Col style={{ textAlign: "center" }} >
                            <img
                                src={logoVerticalAzul}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    height: 150
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 30, width: "90%", marginLeft: "5%", marginRight: "5%" }} >
                        <FormGroup style={{ borderRadius: 10 }} >
                            <Label style={{ fontWeight: "bold", }} >
                                Nombre de Usuario
                            </Label>
                            <InputGroup>
                                <InputGroupText
                                    style={{
                                        borderColor: "transparent transparent #000000 transparent",
                                        backgroundColor: colors.gris_1,
                                        borderRadius: "10px 0px 0px 10px",

                                    }}
                                >
                                    <AccountCircle style={{ color: colors.azul_2 }} />
                                </InputGroupText>
                                <Input
                                    style={{
                                        borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                        backgroundColor: colors.gris_1,
                                        boxShadow: "none",
                                        color: colors.azul_2,
                                        borderRadius: "0px 10px 10px 0px",
                                        fontFamily: "One Dot"
                                    }}
                                    id={"username"}
                                    name={"username"}
                                    value={userData.username}
                                    onChange={(event) => onChangeUserData(event)}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Row>
                    <Row style={{ marginTop: 50, width: "90%", marginLeft: "5%", marginRight: "5%" }} >
                        <FormGroup>
                            <Label style={{ fontWeight: "bold", color: colors.azul_2 }} >
                                Contraseña
                            </Label>
                            <InputGroup>
                                <InputGroupText
                                    style={{
                                        borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                        backgroundColor: colors.gris_1,
                                        borderRadius: "10px 0px 0px 10px"
                                    }}
                                >
                                    <Lock style={{ color: colors.azul_2 }} />
                                </InputGroupText>
                                <Input
                                    style={{
                                        borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                        backgroundColor: colors.gris_1,
                                        boxShadow: "none",
                                        fontFamily: "One Dot"
                                    }}
                                    id={"password"}
                                    name={"password"}
                                    value={userData.password}
                                    onChange={(event) => onChangeUserData(event)}
                                    type={isVisiblePassword ? "text" : "password"}
                                />
                                <InputGroupText
                                    style={{
                                        borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                        backgroundColor: colors.gris_1,
                                        borderRadius: "0px 10px 10px 0px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        setIsVisiblePassword(!isVisiblePassword);
                                    }}
                                >
                                    {isVisiblePassword ? <Visibility style={{ color: colors.azul_2 }} /> : <VisibilityOff style={{ color: colors.azul_2 }} />}
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Row>
                    {
                        labelWarning.length === 0 ?
                            null
                            :
                            <Row style={{ marginTop: 20, width: "90%", marginLeft: "5%", marginRight: "5%", }} >
                                <Col style={{ textAlign: "left" }} >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: 18,
                                            color: colors.warning,
                                        }}
                                    >
                                        {labelWarning}
                                    </Label>
                                </Col>
                            </Row>
                    }
                    <Row style={{ marginTop: 0, width: "90%", marginLeft: "5%", marginRight: "5%", position: "absolute", bottom: 75, }} >
                        <Col
                            style={{
                                width: "50%",
                                minWidth: "50%",
                                maxWidth: "50%"
                            }}
                        >
                            <Button
                                style={{
                                    width: "90%",
                                    backgroundColor: "rgb(255,255,255,0.3)",
                                    borderColor: colors.azul_2,
                                    color: colors.azul_2,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    borderRadius: 10,
                                }}
                                onClick={() => {
                                    setUserData({ username: "", password: "" });
                                    setLabelWarning("");
                                    ComponentTool.focus("username");
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col
                            style={{
                                width: "50%",
                                minWidth: "50%",
                                maxWidth: "50%"
                            }}
                        >
                            <Button
                                style={{
                                    width: "90%",
                                    backgroundColor: colors.rojo,
                                    borderColor: colors.rojo,
                                    color: colors.blanco,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    borderRadius: 10,
                                }}
                                onClick={() => {
                                    userLogin();
                                }}
                            >
                                Iniciar
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0, width: "90%", marginLeft: "5%", marginRight: "5%", position: "absolute", bottom: 20, }} >
                        <Col style={{ fontWeight: "bold" }} >
                            <Label >
                                ¿No eres del area de marketing?,
                            </Label>
                            <Label style={{ marginLeft: 7, color: colors.rojo, cursor: "pointer" }} onClick={() => setIsOpenModal(true)} >
                                Has click aquí
                            </Label>
                        </Col>

                    </Row>
                </Col>
                <Col
                    style={{
                        width: "50%",
                        minWidth: "50%",
                        maxWidth: "50%",
                        // backgroundColor: colors.azul_1,
                        backgroundImage: "linear-gradient(to bottom, #006491, #006491, #006491, #006491, #006491, #026794, #036a98, #056d9b, #0a73a3, #0e7aab, #1280b3, #1687bb)",
                        borderRadius: "0px 10px 10px 0px",
                    }}
                >
                    <Row style={{ marginTop: 50, width: "90%", marginLeft: "5%", marginRight: "5%" }} >
                        <Col style={{ textAlign: "center" }} >

                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <Label
                                    style={{
                                        fontSize: 50,
                                        color: "#122F51",
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        zIndex: 1,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                    }}
                                >
                                    bienvenido de nuevo
                                </Label>
                                <Label
                                    style={{
                                        fontSize: 50,
                                        color: "#FFFFFF",
                                        fontFamily: "Pizza Press Fill",
                                        zIndex: 2,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                        position: "absolute"
                                    }}
                                >
                                    bienvenido de nuevo
                                </Label>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ width: "90%", marginLeft: "5%", marginRight: "5%" }} >
                        <Col style={{ textAlign: "center" }} >
                            <img
                                src={marketing}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    width: "85%",
                                    // border: " 2px solid #FFF",
                                    borderRadius: 40
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0, width: "90%", marginLeft: "5%", marginRight: "5%", position: "absolute", bottom: 50, left: 0, }} >
                        <Col style={{ textAlign: "center" }} >
                            {/* <Label style={{ fontWeight: "bold", fontSize: 30, color: colors.blanco, }} >
                                Area de Marketing
                            </Label> */}
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <Label
                                    style={{
                                        fontSize: 36,
                                        color: "#122F51",
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        zIndex: 1,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                    }}
                                >
                                    Area de Marketing
                                </Label>
                                <Label
                                    style={{
                                        fontSize: 36,
                                        color: "#FFFFFF",
                                        fontFamily: "Pizza Press Fill",
                                        zIndex: 2,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                        position: "absolute"
                                    }}
                                >
                                    Area de Marketing
                                </Label>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0, width: "90%", marginLeft: "5%", marginRight: "5%", position: "absolute", bottom: 5, left: 0, }} >
                        <Col style={{ textAlign: "center" }} >
                            {/* <Label style={{ fontWeight: "bold", fontSize: 18, color: colors.blanco, }} >
                                Noviembre 2023
                            </Label> */}
                            <div
                                style={{
                                    position: "relative",
                                }}
                            >
                                <Label
                                    style={{
                                        fontSize: 22,
                                        color: "#122F51",
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        zIndex: 1,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                    }}
                                >
                                    Noviembre 2023
                                </Label>
                                <Label
                                    style={{
                                        fontSize: 22,
                                        color: "#FFFFFF",
                                        fontFamily: "Pizza Press Fill",
                                        zIndex: 2,
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        // marginTop: -9,
                                        position: "absolute"
                                    }}
                                >
                                    Noviembre 2023
                                </Label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Block height={"10vh"} />
            <Modal
                isOpen={isOpenModal}
                size="xl"
                style={{
                    fontFamily: "Pizza Press Fill",
                    maxWidth: "40%"
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul_2
                            }}
                            onClick={() => {
                                setIsOpenModal(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul_1,
                    }}
                >
                    <div
                        style={{
                            height: 50,
                            position: "relative",
                        }}
                    >
                        <Label
                            style={{
                                fontSize: 36,
                                color: "#00A0DB",
                                fontFamily: "Pizza Press Reverse Shadow",
                                zIndex: 1,
                                top: 0,
                                left: 0,
                                right: 0,
                                // marginTop: -9,
                            }}
                        >
                            GENTE WOW
                        </Label>
                        <Label
                            style={{
                                fontSize: 36,
                                color: "#FFFFFF",
                                fontFamily: "Pizza Press Fill",
                                zIndex: 2,
                                top: 0,
                                left: 0,
                                right: 0,
                                // marginTop: -9,
                                position: "absolute"
                            }}
                        >
                            GENTE WOW
                        </Label>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Block height={10} />
                    <Row>
                        <Col>
                            <Label style={{ fontSize: 24, color: colors.azul_2 }} >
                                ¿A que area perteneces?
                            </Label>
                        </Col>
                    </Row>
                    <Block height={10} />
                    <Row>
                        <Col
                            style={{
                                width: "33%",
                                minWidth: "33%",
                                maxWidth: "33%",
                            }}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    backgroundColor: colors.rojo,
                                    borderColor: colors.rojo,
                                    color: colors.blanco,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    borderRadius: 10,
                                    fontSize: 18,
                                }}
                                onClick={() => {
                                    window.location.href = "/user/director/login";
                                }}
                            >
                                Dirección
                            </Button>
                        </Col>
                        <Col
                            style={{
                                width: "33%",
                                minWidth: "33%",
                                maxWidth: "33%",
                            }}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    backgroundColor: colors.rojo,
                                    borderColor: colors.rojo,
                                    color: colors.blanco,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    borderRadius: 10,
                                    fontSize: 18,
                                }}
                                onClick={() => {
                                    window.location.href = "/user/marketing/login";
                                }}
                            >
                                Area de Marketing
                            </Button>
                        </Col>
                        <Col
                            style={{
                                width: "33%",
                                minWidth: "33%",
                                maxWidth: "33%",
                            }}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    backgroundColor: colors.rojo,
                                    borderColor: colors.rojo,
                                    color: colors.blanco,
                                    fontWeight: "bold",
                                    boxShadow: "none",
                                    borderRadius: 10,
                                    fontSize: 18,
                                }}
                                onClick={() => {
                                    window.location.href = "/user/manager/login";
                                }}
                            >
                                Gerente de Unidad
                            </Button>
                        </Col>
                    </Row>
                    <Block height={10} />
                </ModalBody>
            </Modal>
        </section>
    );
}

export default MarketingLogin;
