import Swal from "sweetalert2";
import ServiceConfig from "../configs/Service.config"
import axios from "axios"
import colors from "../configs/Colors.config";
import UserMiddleware from "../middleware/User.middleware";

const url = ServiceConfig.getUrlApiAdminMarketing() + "/stores";

const messageError = (messageError) => {
    console.error(messageError);
    Swal.fire({
        icon: "error",
        title: "Error",
        text: "❌ ¡Algo ha salido mal! Por favor, 🔄 intenta más tarde o 📧 comunícate con el soporte técnico. 😞🔧🚫",
        footer: messageError,
        confirmButtonColor: colors.azul_1
    });
}

const getStoreStructure = () => {
    return {
        IdStore: 0,
        IdState: 0,
        nameState: '',
        nameCity: '',
        IdCity: 0,
        IdFranchise: 0,
        nameFranchises: '',
        IdStatus: 1,
        UDN: '',
        nameStore: '',
        address: '',
        urlGoogleMaps: '',
        urlGoogleStreetView: '',
        phone: '',
        timeOpening: '09:00:00',
        timeClosing: '23:00:00',
    }

}

const getStoreList = async () => {

    let error;
    let message;
    let storeList = [];

    try {
        const response = await axios({
            url: `${url}/list`,
            method: "GET",
            headers: {
                token: UserMiddleware.getTokenStatic(),
            }
        });
        if (response.error) {
            messageError(response.message);
        }
        error = false;
        message = "successful";
        storeList = response.data.storeList;
    } catch (dataError) {
        error = true;
        message = dataError
    }

    return {
        error,
        message,
        storeList,
    }

}

const getStoreListByState = async (IdState) => {

    let error;
    let message;
    let storeList = [];

    try {
        const response = await axios({
            url: `${url}/state/${IdState}`,
            headers: {
                token: UserMiddleware.getTokenStatic(),
            }
        });
        if (response.error) {
            messageError(response.message)
        }
        error = false;
        message = "successful";
        storeList = response.data.storeList;
    } catch (dataError) {
        error = true;
        message = dataError
    }

    return {
        error,
        message,
        storeList: storeList.sort((a, b) => (a["nombreUnidad"] > b["nombreUnidad"] ? 1 : a["nombreUnidad"] < b["nombreUnidad"] ? -1 : 0)),
    }

}

const getStoreByUDN = async (UDN) => {

    let error;
    let message;
    let store = {};

    try {
        const response = await axios({
            url: `${url}/${UDN}`,
            headers: {
                token: UserMiddleware.getTokenStatic()
            }
        });
        if (response.error) {
            messageError(response.message)
        }
        error = false;
        message = "successful";
        store = response.data.store;
        if (store === undefined) {
            document.body.innerHTML = "<div></div>"
            throw new Error("#%$l.hh");
        }

    } catch (dataError) {
        error = true;
        message = dataError
    }

    return {
        error,
        message,
        store
    };
}

const deleteStoreById = async (IdStore) => {

    let error;
    let message;
    let status;

    try {
        const response = await axios({
            url: `${url}/delete/${IdStore}`,
            method: "DELETE",
            headers: {
                token: UserMiddleware.getToken()
            }
        });
        error = false;
        message = "successful";
        status = response.data.status
    } catch (dataError) {
        error = true;
        message = dataError;
        status = 500;
    }

    return {
        error,
        message,
        status,
    }

}

const storeSearch = async (reference) => {

    let error;
    let message;
    let storeList = [];

    try {
        const response = await axios({
            url: `${url}/search`,
            method: "POST",
            headers: {
                token: UserMiddleware.getToken()
            },
            data: {
                reference
            }
        });
        error = false;
        message = "successful";
        storeList = response.data.storeList
    } catch (dataError) {
        error = true;
        message = dataError;
    }

    return {
        error,
        message,
        storeList,
    }

}

const storeSaveUpdate = async (store) => {

    let error;
    let message;
    let IdStore = [];
    try {
        const response = await axios({
            url: `${url}/save`,
            method: "POST",
            headers: {
                token: UserMiddleware.getToken()
            },
            data: {
                store
            }
        });
        error = false;
        message = "successful";
        IdStore = response.data.IdStore
    } catch (dataError) {
        error = true;
        message = dataError;
    }

    return {
        error,
        message,
        IdStore,
    }

}

const StoreService = {
    getStoreStructure,
    getStoreByUDN,
    getStoreList,
    getStoreListByState,
    deleteStoreById,
    storeSearch,
    storeSaveUpdate,
}

export default StoreService;