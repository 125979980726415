import md5 from "md5";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const encryptText = (text) => {
    const displacement = 16
    text = md5(text);
    let alphabet = "xN_Abc}[$v(p]kCw&iU^]G:>e'f,FHDVbmJWldcE!OvZA;qRh.t}<LgozXjKs@?u(r|QIS-P^YnM{%T";
    let textEncryption = "";

    for (let i = 0; i < text.length; i++) {
        let character = text[i];
        if (alphabet.includes(character)) {
            let indice = (alphabet.indexOf(character) + displacement) % alphabet.length;
            textEncryption += alphabet[indice];
        } else {
            textEncryption += character;
        }
    }

    return textEncryption;
}

const verifyToken = async () => {
    const user = await cookies.get('user', {
        path: '/'
    });
    return user === undefined ? false : true;
}

const getToken = () => {
    const {
        token
    } = cookies.get('user', {
        path: '/'
    });
    return token;
}

const getTokenStatic = () =>{
    const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJEb21pbm9zIEdQTSIsImlhdCI6MTcwMDU3OTczNSwiZXhwIjoxNzMyMTE1Nzk4LCJhdWQiOiJodHRwczovL2RvbWlub3NncG0uY29tLm14LyIsInN1YiI6ImFkbWluQGRvbWlub3NncG0uY29tLm14IiwiR2l2ZW5OYW1lIjoiRG9taW5vcyBHUE0iLCJTdXJuYW1lIjoiRG9taW5vcyBHUE0iLCJFbWFpbCI6ImFkbWluQGRvbWlub3NncG0uY29tLm14IiwiUm9sZSI6WyJNYW5hZ2VyIiwiUHJvamVjdCBBZG1pbmlzdHJhdG9yIl19.USy_9z3RR8gv6gO44cMRILeDj7LAzks6jRrKQN8pI1w";
    return token;
}

const UserMiddleware = {
    encryptText,
    verifyToken,
    getToken,
    getTokenStatic,
}

export default UserMiddleware