import axios from "axios";
import UserMiddleware from "../middleware/User.middleware"
import Swal from "sweetalert2";
import colors from "../configs/Colors.config";
import ServiceConfig from "../configs/Service.config"

const url = ServiceConfig.getUrlApiAdminMarketing() + "/dominos";

const messageError = (messageError) => {
    console.error(messageError);
    Swal.fire({
        icon: "error",
        title: "Error",
        text: "❌ ¡Algo ha salido mal! Por favor, 🔄 intenta más tarde o 📧 comunícate con el soporte técnico. 😞🔧🚫",
        footer: messageError,
        confirmButtonColor: colors.azul_1
    });
}

const getDominosPricesStructure = (IdDominos) => {
    const prices = {
        IdDominos,
        priceBasicMedium: 0,
        priceBasicBig: 0,
        priceBasicBigExtra: 0,
        priceFavoriteMedium: 0,
        priceFavoriteBig: 0,
        priceFavoriteBigExtra: 0,
        pricePremiumMedium: 0,
        pricePremiumBig: 0,
        pricePremiumBigExtra: 0
    };

    return prices;
}


const getDominosPriceList = async (IdDominos) => {

    let response = null;
    let error = false;
    let data = [];
    let price = [];
    
    try {
        response = await axios({
            url: `${url}/list/precies/${IdDominos}`,
            method: "GET",
            headers: {
                token: UserMiddleware.getTokenStatic()
            },
        });
        data = response.data;
        if (response.error) {
            messageError(response.message)
        }
        if (data.error) {
            messageError("Error en el servidor, revisar api")
        }
        price = data.dominosPrice

    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,
        price
    });

}

const dominosSavePrecies = async (dominos) => {

    let response = null;
    let error = false;
    let data = [];    

    try {
        response = await axios({
            url: `${url}/save/precies`,
            method: "PUT",
            headers: {
                token: UserMiddleware.getToken()
            },
            data: {
                dominos
            }
        });
        data = response.data;
        if (response.error) {
            messageError(response.message)
        }
        if (data.error) {
            messageError("Error en el servidor, revisar api")
        }        

    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,
        data
    });

}

const DominosServices = {
    getDominosPricesStructure,
    getDominosPriceList,
    dominosSavePrecies
}

export default DominosServices;