import axios from "axios";
import UserMiddleware from "../middleware/User.middleware"
import Swal from "sweetalert2";
import colors from "../configs/Colors.config";
import ServiceConfig from "../configs/Service.config"

const url = ServiceConfig.getUrlApiAdminMarketing() + "/locates";

const messageError = (messageError) => {
    console.error(messageError);
    Swal.fire({
        icon: "error",
        title: "Error",
        text: "❌ ¡Algo ha salido mal! Por favor, 🔄 intenta más tarde o 📧 comunícate con el soporte técnico. 😞🔧🚫",
        footer: messageError,
        confirmButtonColor: colors.azul_1
    });
}

const getStatesList = async () => {

    let response = null;
    let error = false;
    let stateList = [];
    try {
        response = await axios({
            url: `${url}/states/list`,
            method: "GET",
            headers: {
                token: UserMiddleware.getToken()
            },
        });
        stateList = response.data.stateList;
        if(response.error){
            messageError(response.message)
        }
    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,        
        stateList
    });

}

const getCitesListByIdState = async (IdStates) => {

    let response = null;
    let error = false;
    let citiesList = [];
    try {
        response = await axios({
            url: `${url}/cites/${IdStates}/list`,
            method: "GET",
            headers: {
                token: UserMiddleware.getToken()
            },
        });
        citiesList = response.data.citiesList;
        if(response.error){
            messageError(response.message)
        }
    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,        
        citiesList
    });

}

const LocateService = {
    getStatesList,
    getCitesListByIdState,
}

export default LocateService