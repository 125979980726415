
import { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Cookies from 'universal-cookie';
import logoDominos from "../../../assets/images/logo/logo.png";
import { Button, Col, Row } from 'reactstrap';
import { AddBusiness, ArrowBackIos, ArrowForwardIos, AttachMoney, Description, ExitToApp, List, LocalPizza, Person, Settings, Star, Store } from '@mui/icons-material';
import colors from '../../../configs/Colors.config';
import Swal from 'sweetalert2';
import TimeTool from '../../../Tools/Time.tool';
import logo from "../../resources/logo.png";

/** Indices de las paginas */
import StoreAdminList from './IndexPages/StoreAdminList';
import IngredientsAdmin from './IndexPages/IngredientsAdmin';
import UserMiddleware from '../../../middleware/User.middleware';
import LoadPage from '../../LoadPage';
import AdditionalAdmin from './IndexPages/AdditionalAdmin';
import Index from './IndexPages/Index';

const cookies = new Cookies();

const DashboardUser = () => {

    const [collapsed, setCollapsed] = useState(false);
    const [open, setOpen] = useState(false);
    const [start, setStart] = useState(false);
    const [indexPage, setIndexPage] = useState(0);



    useEffect(() => {
        loadPage();
        const favicon = document.createElement('link');
        favicon.rel = 'icon';
        favicon.type = 'image/x-icon';
        favicon.href = logo

        document.head.appendChild(favicon);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPage = async () => {
        let response = await UserMiddleware.verifyToken();

        if (!response) {
            await TimeTool.sleep(3);
            document.body.innerHTML = `<div style="background-image: linear-gradient(to bottom, #006491, #016998, #026e9e, #0473a5, #0578ac, #067cb1, #067fb6, #0783bb, #0786bf, #0789c4, #078dc8, #0790cd);  width: 100%; height: 100vh" ></div>`;
            Swal.fire({
                icon: "warning",
                title: "Acceso Denegado",
                text: "Tienes que loguearte para poder usar el sistema web 🔒💻",
                timerProgressBar: true,
                confirmButtonText: "Ir al login",
                timer: 20000,
                confirmButtonColor: colors.azul_1
            }).then(() => {
                window.location.href = "/user/marketing/login"
            });
        } else {
            setStart(true);
        }
    }

    const handleOpenChange = (newOpenState) => {
        setOpen(newOpenState);
    };

    return (

        start ?
            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundImage: "linear-gradient(to bottom, #006491, #016998, #026e9e, #0473a5, #0578ac, #067cb1, #067fb6, #0783bb, #0786bf, #0789c4, #078dc8, #0790cd)",
                    fontFamily: "Pizza Press Fill",
                }}
            >
                <Sidebar
                    collapsed={collapsed}
                    style={{
                        height: "100vh",
                        backgroundColor: "#FBFBFB",
                        position: "fixed",
                        color: colors.azul_1,
                        fontWeight: "bold",
                        zIndex: 1,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            backgroundColor: "#FBFBFB"
                        }}
                    >

                        <center>
                            <img
                                src={logoDominos}
                                alt="No se pudo cargar la imagen"
                                height={collapsed ? 50 : 100}
                                style={{
                                    marginTop: collapsed ? 30 : 10,
                                    transition: 'margin-left 1.3s ease'
                                }}
                            />
                        </center>

                        <Row>
                            <Col
                                style={{
                                    textAlign: collapsed ? "center" : "right"
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setCollapsed(!collapsed);
                                    }}
                                    style={{
                                        marginTop: collapsed ? 30 : 0,
                                        backgroundColor: "#FBFBFB",
                                        color: colors.azul_1,
                                        borderColor: "#FBFBFB",
                                        boxShadow: "none"
                                    }}
                                >
                                    {collapsed ? <ArrowForwardIos /> : <ArrowBackIos />}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Menu>
                        <SubMenu
                            label="Unidades"
                            icon={<Store style={{ color: colors.azul_1 }} />}
                        >
                            <MenuItem
                                icon={<List style={{ color: colors.azul_1 }} />}
                                onClick={() => {
                                    setOpen(false);
                                    setIndexPage(1)
                                }}
                            >
                                Listar
                            </MenuItem>
                            <MenuItem
                                icon={<AddBusiness style={{ color: colors.azul_1 }} />}
                                onClick={() => {
                                    setOpen(true);
                                    setIndexPage(1);
                                }}
                            >
                                Agregar
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Precios"
                            icon={<AttachMoney style={{ color: colors.azul_1 }} />}
                        >

                            <MenuItem
                                icon={<LocalPizza style={{ color: colors.azul_1 }} />}
                                onClick={() => setIndexPage(2)}
                            >
                                Domino's
                            </MenuItem>

                            <MenuItem
                                icon={<Star style={{ color: colors.azul_1 }} />}
                                onClick={() => {
                                    setOpen(true);
                                    setIndexPage(3);
                                }}
                            >
                                Adicionales
                            </MenuItem>
                        </SubMenu>



                        <div style={{ width: "90%", marginRight: "5%", marginLeft: "5%", height: 2, backgroundColor: colors.azul_1 }} ></div>
                        <MenuItem
                            icon={<Description style={{ color: colors.azul_1 }} />}
                            onClick={() => setIndexPage(5)}
                        >
                            Hojas de Requisicion
                        </MenuItem>
                    </Menu>

                    <Menu
                        style={{
                            position: "absolute",
                            bottom: 0,
                            width: "100%"
                        }}
                    >
                        <SubMenu
                            label="Configuraciones"
                            icon={<Settings />}
                        >
                            <MenuItem
                                icon={<Person />}
                            >
                                Mi perfil
                            </MenuItem>
                            {/* <SubMenu
                                label="Usuarios"
                                icon={<PeopleAlt style={{ color: colors.azul_1 }} />}
                            >
                                <MenuItem
                                    icon={<List style={{ color: colors.azul_1 }} />}
                                    onClick={() => setIndexPage(6)}
                                >
                                    Listar
                                </MenuItem>
                                <MenuItem
                                    icon={<PersonAdd style={{ color: colors.azul_1 }} />}
                                    onClick={() => setIndexPage(7)}
                                >
                                    Agregar
                                </MenuItem>
                            </SubMenu> */}
                            <MenuItem
                                icon={<ExitToApp />}
                                onClick={() => {
                                    Swal.fire({
                                        title: "¡Atención!",
                                        text: "¿Estas seguro de que quieres cerrar sesión?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Si",
                                        cancelButtonText: "No",
                                        reverseButtons: true,
                                        cancelButtonColor: "grey",
                                        confirmButtonColor: colors.azul_1
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            cookies.remove("user", { path: '/' });
                                            TimeTool.sleep(0.3).then(() => { window.location.href = "/user/marketing/login" });
                                        }
                                    });

                                }}
                            >
                                Salir
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </Sidebar>;
                {indexPage === 0 && <Index collapsed={collapsed} />}
                {indexPage === 1 && <StoreAdminList collapsed={collapsed} open={open} onOpenChange={handleOpenChange} />}
                {indexPage === 2 && <IngredientsAdmin collapsed={collapsed} />}
                {indexPage === 3 && <AdditionalAdmin collapsed={collapsed} />}
            </section >
            :
            <LoadPage />

    );
}

export default DashboardUser;