import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import Store from "./pages/Store";
import StoreList from "./pages/StoreList";
import MenuDominos from "./pages/MenuDominos";
import MarketingDashboard from "./pages/users/marketing/MarketingDashboard";
import MarketingLogin from "./pages/users/marketing/MarketingLogin";
import ManagerLogin from "./pages/users/manager/ManagerLogin";
import DirectorLogin from "./pages/users/director/DirectorLogin";

const App = () => {

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <>
      <Router>
        <Routes>

          <Route exact path="/" element={<StoreList />} />
          <Route exact path="/:myUdn" element={<Store />} />
          <Route exact path="/menu" element={<MenuDominos />} />
          
          {/* Area de marketing  */}
          <Route exact path="/user/marketing/login" element={<MarketingLogin />} />
          <Route exact path="/user/marketing/dashboard" element={<MarketingDashboard />} />

          {/* Gerentes */}
          <Route exact path="/user/manager/login" element={<ManagerLogin />} />

          {/* Dirección */}
          <Route exact path="/user/director/login" element={<DirectorLogin />} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
