const isInProduction = true


const getUrlApiAdminMarketing = () => {
    
    let urlApiAdminMarketing;
    if(isInProduction){
        urlApiAdminMarketing = "https://unidades.dominosgpm.com.mx/apiAdminMarketing";
    }else{
        urlApiAdminMarketing = "http://192.168.1.87:10000/apiAdminMarketing"; 
    }
    return urlApiAdminMarketing;
}

const ServiceConfig = {
    getUrlApiAdminMarketing,
}

export default ServiceConfig;