import { Button, Card, CardBody, CardText, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import colors from "../../../../configs/Colors.config";
import { useState } from "react";
import { useEffect } from "react";
import AdditionalServices from "../../../../Services/Additional.service";
import { IconButton } from "@mui/material";
import { Cached, Close } from "@mui/icons-material";
import Block from "../../../../components/Block";
import { ToastContainer, toast } from "react-toastify";
import ComponentTool from "../../../../Tools/Component.tool";
import TimeTool from "../../../../Tools/Time.tool";



const AdditionalAdmin = ({ collapsed }) => {

    const [additionalList, setAdditionalList] = useState([]);
    const [additional, setAdditional] = useState(AdditionalServices.getAdditionalStructure());
    const [isOpenModal, setOpenModal] = useState(false);
    const [onFocusPrice, setOnFocusPrice] = useState(false);

    useEffect(() => {
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPage = async () => {
        let { additionalList } = await AdditionalServices.getAdditionalList();
        setAdditionalList(additionalList);
    }

    const onChangeAdditional = ({ target }) => {
        const { name, value } = target;
        setAdditional({
            ...additional,
            [name]: value
        });
    }

    const additionalSave = async () => {
        console.table(additional)
        let IdToast = toast.loading("Guardando precios...");
        await AdditionalServices.additionalSave(additional);
        await toast.update(IdToast, {
            render: 'Precios actualizado',
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            closeButton: true,
        })
        await loadPage();
    }

    return (
        <div
            style={{
                // // width: "100%",
                marginLeft: collapsed ? 80 : 250,
                marginRight: 0,
                height: "100vh",
                marginTop: -25,
                padding: 10,
                fontFamily: "Pizza Press Fill",

            }}
        >
            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <section
                style={{
                    width: "100%",
                    height: "97vh",
                    backgroundColor: colors.blanco,
                    borderRadius: 10,
                    padding: 10,
                    overflowY: "scroll",
                }}
            >
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col>
                        <Label style={{ fontSize: 28, fontWeight: "bold" }} >
                            Adicionales dados de alta en el sistema
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "right" }} >
                        <IconButton
                            style={{
                                backgroundColor: colors.rojo,
                                color: colors.blanco,
                            }}
                            onClick={async () => {
                                let IdToast = await toast.loading("Actualizando pagina...");
                                await loadPage();
                                await toast.update(IdToast, {
                                    render: 'Pagina Actualizada',
                                    isLoading: false,
                                    type: toast.TYPE.SUCCESS,
                                    autoClose: 3000,
                                    closeButton: true,
                                })
                            }}
                        >
                            <Cached />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    {additionalList.map((additional) => {
                        return (
                            <Col xs={12} sm={6} md={4} lg={3} style={{ marginTop: 20 }} >
                                <Card
                                    style={{
                                        width: '18rem',
                                        height: 450
                                    }}
                                >
                                    <img
                                        alt="Sample"
                                        src={additional.img}
                                    />
                                    <CardBody>
                                        <Row>
                                            <Col style={{ width: "70%", maxWidth: "70%", minWidth: "70%" }} >
                                                <Label style={{ fontSize: 24 }} >
                                                    {additional.nameAdditional}
                                                </Label>
                                            </Col>
                                            <Col style={{ textAlign: "right", width: "30%", maxWidth: "30%", minWidth: "30%" }} >
                                                <Label style={{ fontSize: 24, color: colors.azul_1 }} >
                                                    ${additional.priceAdditional}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <CardText style={{ height: "70px", overflowY: "scroll" }} >
                                            {additional.descriptionAdditional}
                                        </CardText>
                                        <Row  >
                                            <Col style={{ textAlign: "right" }} >
                                                <Button
                                                    onClick={() => {
                                                        setOpenModal(true);
                                                        setAdditional(additional);
                                                    }}
                                                    style={{
                                                        boxShadow: "none",
                                                        backgroundColor: colors.rojo,
                                                        color: colors.blanco,
                                                        fontWeight: "bold",
                                                        borderColor: colors.rojo,
                                                    }}
                                                >
                                                    Editar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}

                </Row>
            </section>
            <Modal
                isOpen={isOpenModal}
                onOpened={() => {
                    ComponentTool.focus("priceAdditional");
                    TimeTool.sleep(0.3).then(() => {
                        document.getElementById("priceAdditional").click();
                    });
                }}
                size="lg"
                style={{
                    fontFamily: "Pizza Press Fill",
                    maxWidth: "70%"
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{
                                backgroundColor: colors.blanco,
                                color: colors.azul_1,
                            }}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul_1
                    }}
                >
                    <Label style={{ fontWeight: "bold", fontSize: 28, color: colors.blanco }} >
                        Datos del Adicional
                    </Label>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col style={{ width: "70%", maxWidth: "70%", minWidth: "70%" }} >
                            <Row style={{}} >
                                <Col>
                                    <FormGroup style={{ borderRadius: 10 }} >
                                        <Label style={{ fontWeight: "bold", fontSize: 22 }} >
                                            Nombre del adicional
                                        </Label>
                                        <Input
                                            style={{
                                                borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                                backgroundColor: colors.gris_1,
                                                boxShadow: "none",
                                                color: colors.azul_2,
                                                borderRadius: "10px",
                                                fontFamily: "One Dot",
                                                cursor: "not-allowed"
                                            }}
                                            disabled
                                            value={additional.nameAdditional}
                                            onChange={({ target }) => {

                                            }}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") additionalSave();
                                            }}
                                            id={"nameAdditional"}
                                            name={"nameAdditional"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup style={{ borderRadius: 10 }} >
                                        <Label style={{ fontWeight: "bold", fontSize: 22 }} >
                                            Precio {onFocusPrice ? additional.priceAdditional.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) : ""}
                                        </Label>
                                        <Input
                                            style={{
                                                borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                                backgroundColor: colors.gris_1,
                                                boxShadow: "none",
                                                color: colors.azul_2,
                                                borderRadius: "10px",
                                                fontFamily: "One Dot",
                                                textAlign: "right"
                                            }}
                                            value={onFocusPrice ? additional.priceAdditional : additional.priceAdditional.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                                            onFocus={() => setOnFocusPrice(true)}
                                            onBlur={() => setOnFocusPrice(false)}
                                            name="priceAdditional"
                                            onChange={(event) => {
                                                let { value } = event.target;                                                                                                
                                                if (value.length === 0) {
                                                    value = 0;
                                                } else {                                                    
                                                    if (value.charAt(0) === "0") {
                                                        value = value.slice(1);
                                                    }
                                                }
                                                if (/^\d+$/.test(value)) {
                                                    let data = { target: { name: "priceAdditional", value: parseFloat(value) } }
                                                    onChangeAdditional(data);
                                                }

                                            }}
                                            onClick={(event) => {
                                                event.target.select();
                                            }}
                                            onKeyDown={({ key }) => {
                                                if (key === "Enter") additionalSave();
                                                if (key === "Escape") { setOpenModal(false); setAdditional(AdditionalServices.getAdditionalStructure()) }
                                            }}
                                            id={"priceAdditional"}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup style={{ borderRadius: 10 }} >
                                    <Label style={{ fontWeight: "bold", fontSize: 22 }} >
                                        Descripción
                                    </Label>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: "10px",
                                            fontFamily: "One Dot",
                                            height: 70,
                                            cursor: "not-allowed"
                                        }}
                                        disabled
                                        type={"textarea"}
                                        value={additional.descriptionAdditional}
                                        onChange={({ target }) => {

                                        }}
                                        onKeyDown={({ key }) => {
                                            //if (key === "Enter") storeSearch();
                                        }}
                                        id={"descriptionAdditional"}
                                        name={"descriptionAdditional"}
                                    />
                                </FormGroup>
                            </Row>
                        </Col>
                        <Col style={{ width: "30%", maxWidth: "30%", minWidth: "30%" }} >
                            <Row>
                                <center>
                                    <img
                                        src={additional.img}
                                        alt="No se pudo cargar la imagen"
                                        style={{
                                            borderRadius: 10,
                                            width: "100%",
                                            height: 200
                                        }}
                                    />
                                </center>
                            </Row>
                        </Col>
                    </Row>


                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            additionalSave();
                        }}
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.rojo,
                            color: colors.blanco,
                            fontWeight: "bold",
                            borderColor: colors.rojo,
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );

}

export default AdditionalAdmin;