import axios from "axios";
import UserMiddleware from "../middleware/User.middleware"
import Swal from "sweetalert2";
import colors from "../configs/Colors.config";
import ServiceConfig from "../configs/Service.config"

const url = ServiceConfig.getUrlApiAdminMarketing()+"/users";

const messageError = (messageError) => {
    console.error(messageError);
    Swal.fire({
        icon: "error",
        title: "Error",
        text: "❌ ¡Algo ha salido mal! Por favor, 🔄 intenta más tarde o 📧 comunícate con el soporte técnico. 😞🔧🚫",
        footer: messageError,
        confirmButtonColor: colors.azul_1
    });
}

const login = async (user) => {
    const userData = {
        username: user.username,
        password: UserMiddleware.encryptText(user.password)
    }

    let response = null;
    let error = false;

    try {
        response = await axios({
            url: `${url}/login`,
            method: "POST",
            data: {
                userData
            }
        });
        response = response.data;
        if(response.error){
            messageError(response.message)
        }
    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,
        data: response
    });

}

const UserService = {
    login,
}

export default UserService