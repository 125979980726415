
const format12 = (time) => {

    if(time.length === 0) return "---"; 

    const hours = parseInt(time.substring(0, 2) % 12);
    const minutes = time.substring(3, 5);
    const seconds = time.substring(6, 8);
    const period = parseInt(time.substring(0, 2)) >= 12 ? 'pm.' : 'am.';

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${period}`;
};

const sleep = (seconds) => new Promise(
    resolve => setTimeout(resolve, (seconds * 1000))
);


const TimeTool = {
    format12,
    sleep
};

export default TimeTool;
