import StoreService from "../../../../Services/Store.service";
import colors from "../../../../configs/Colors.config";
import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Col, DropdownItem, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Table from "react-data-table-component";
import { IconButton, FormGroup } from "@mui/material";
import { Cached, Delete, Edit, Search, Close, Add } from "@mui/icons-material";
import Block from "../../../../components/Block";
import Swal from "sweetalert2";
import Iframe from "react-iframe";
import mapa from "../../../../assets/images/resources/mapa.svg"
import ComponentTool from "../../../../Tools/Component.tool";
import LocateService from "../../../../Services/Locate.service";

const StoreAdminList = ({ collapsed, open, onOpenChange }) => {

    const [storeList, setStoreList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [labelTable, setLabelTable] = useState("");
    const [labelDataStore, setLabelDataStore] = useState("");
    const [reference, setReference] = useState("");
    const [isOpenModalDataStore, setIsOpenModalDataStore] = useState(false);
    const [showTextReference, setShowTextReference] = useState(false);
    const [isOpenModalMoreDataStore, setIsOpenModalMoreDataStore] = useState(false);
    const [onFocusPhoneStore, setOnFocusPhoneStore] = useState(true);
    const [store, setStore] = useState(StoreService.getStoreStructure())
    const columnTableStore = [{ name: <p style={{ fontSize: 20, }}>UDN</p>, selector: row => <label style={{ fontSize: 16 }} > {row.UDN} </label>, }, { name: <p style={{ fontSize: 20, }}>Nombre</p>, selector: row => <label style={{ fontSize: 16 }} > {row.nameStore} </label>, }, { name: <p style={{ fontSize: 20, }}>Estado/Ciudad</p>, selector: row => <label style={{ fontSize: 16 }} > {row.nameState}/{row.nameCity} </label>, }, { name: <p style={{ fontSize: 20, }}>Franquicia</p>, selector: row => <label style={{ fontSize: 16 }} > {row.nameFranchises} </label>, }, { name: <p style={{ fontSize: 16 }}></p>, selector: row => <> <IconButton onClick={() => { let { IdStore, nameStore } = row; deleteStoreById(IdStore, nameStore); }} > <Delete style={{ color: colors.rojo }} /> </IconButton> <IconButton onClick={() => { setStore(row); getCitiesByIdStates(row.IdState); setIsOpenModalDataStore(true) }} > <Edit style={{ color: colors.azul_1 }} /> </IconButton> </>, },];


    useEffect(() => {
        console.clear();
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPage = async () => {
        console.clear();
        getStoreList();
        getStatesList();
        if (open) {
            setStore(StoreService.getStoreStructure());
            setIsOpenModalDataStore(true);
        }
        ComponentTool.focus("reference");
    }

    const getStoreList = async () => {
        setLabelTable("Descargando unidades...");
        const response = await StoreService.getStoreList();
        const { error } = response;
        if (!error) {
            const { storeList } = response;
            if (storeList.length === 0) setLabelTable("No hay unidades dadas de alta");
            setStoreList(storeList);
        } else {
            toast.error("No se pudieron obtener las unidades");
        }
    }

    const getStatesList = async () => {
        const response = await LocateService.getStatesList();
        const { error } = response;
        if (!error) {
            const { stateList } = response;
            setStateList(stateList);
        } else {
            toast.error("No se pudieron obtener los estados");
        }
    }

    const getCitiesByIdStates = async (IdState) => {
        const response = await LocateService.getCitesListByIdState(IdState);
        const { error } = response;
        if (!error) {
            const { citiesList } = response;
            setCitiesList(citiesList);
        } else {
            toast.error("No se pudieron obtener las ciudades");
        }
    }

    const storeValidateData = () => {
        const { nameStore, IdFranchise, IdState, address } = store;
        return nameStore.length !== 0 && IdFranchise !== 0 && IdState !== 0 && address.length !== 0;
    };

    const storeSaveUpdate = async () => {

        if (storeValidateData()) {
            const response = await StoreService.storeSaveUpdate(store);
            const { error } = response;
            if (!error) {
                const { IdStore } = response;
                setStore({ ...store, IdStore });
                if (reference.length === 0) { getStoreList(); } else { storeSearch(); }
                setLabelDataStore("");
                toast.success(`Datos de la unidad ${store.IdStore === 0 ? "guardar" : "actualizados"}`);
            } else {
                toast.error("No se pudieron guardar las unidad");
            }
        } else {
            toast.warning(`No se pudo ${store.IdStore === 0 ? "guardar" : "actualizar"} la unidad ${store.nameStore}`);
            setLabelDataStore("Faltan datos por llenar");
        }

    }

    const formatNumberPhone = (phone) => {
        const numberPhone = phone.replace(/\D/g, '');
        const format = numberPhone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1-$2-$3-$4');
        return format;
    }

    const deleteStoreById = (IdStore, nameStore) => {
        Swal.fire({
            title: "¿Eliminar Unidad?",
            text: `¿Estás seguro de que quieres eliminar la unidad ${nameStore} 🤔⚠️?.`,
            confirmButtonColor: colors.rojo,
            reverseButtons: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "No Eliminar",
            footer: `<label>Esta acción no se puede deshacer ❌🚫.</label>`,
            showCancelButton: true,
            timer: 20000,
            timerProgressBar: true,
        }).then(async (response) => {
            let { isConfirmed } = response;
            if (isConfirmed) {
                await StoreService.deleteStoreById(IdStore);
                getStoreList();
                toast.success("Unidad eliminada");
            }
        });
    }

    const storeSearch = async () => {

        if (reference.length === 0) {
            toast.warning("No se hizo la búsqueda");
            setShowTextReference(true);
            ComponentTool.focus("reference");
        } else {
            let response = await StoreService.storeSearch(reference);
            const { error } = response;
            if (!error) {
                const { storeList } = response;
                if (storeList.length === 0) setLabelTable("No hay unidades encontradas");
                setStoreList(storeList);
            } else {
                toast.error("No se pudieron obtener las unidades");
            }
        }

    }

    const onChangeStore = ({ target }) => {
        let { name, value } = target;
        setStore({
            ...store,
            [name]: value
        });
    }

    return (
        <div
            style={{
                // // width: "100%",
                marginLeft: collapsed ? 80 : 250,
                marginRight: 0,
                height: "100vh",
                marginTop: -25,
                padding: 10,
                fontFamily: "Pizza Press Fill",
            }}
        >
            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Row style={{ marginRight: 0, marginLeft: 0, backgroundColor: colors.blanco, borderRadius: "10px 10px 0px 0px", }} >
                <Col
                    style={{
                        width: "70%",
                        maxWidth: "70%",
                        minWidth: "70%",
                    }}
                >
                    <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                        <Label style={{ fontWeight: "bold", fontSize: 22 }} >
                            Buscar Unidad
                        </Label>
                        <InputGroup>

                            <Input
                                style={{
                                    borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                    backgroundColor: colors.gris_1,
                                    boxShadow: "none",
                                    color: colors.azul_2,
                                    borderRadius: "10px 0px 0px 10px",
                                    fontFamily: "One Dot",
                                    fontSize: 20,
                                }}
                                value={reference}
                                onChange={({ target }) => {
                                    let { value } = target;
                                    setReference(value);
                                    setShowTextReference(false);
                                }}
                                onKeyDown={({ key }) => {
                                    if (key === "Enter") storeSearch();
                                }}
                                placeholder="Nombre, UDN"
                                id={"reference"}
                                name={"reference"}
                            />
                            <InputGroupText
                                style={{
                                    borderColor: "transparent transparent #000000 transparent",
                                    backgroundColor: colors.gris_1,
                                    borderRadius: "0px 0px 0px 0px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setReference("");
                                    ComponentTool.focus("reference");
                                    setShowTextReference(false);
                                }}
                            >
                                <Close style={{ color: colors.azul_2 }} />
                            </InputGroupText>
                            <InputGroupText
                                style={{
                                    borderColor: "transparent transparent #000000 transparent",
                                    backgroundColor: colors.gris_1,
                                    borderRadius: "0px 10px 10px 0px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    storeSearch();
                                }}
                            >
                                <Search style={{ color: colors.azul_2 }} />
                            </InputGroupText>
                        </InputGroup>
                        {
                            showTextReference ?
                                <FormText>
                                    <Label
                                        style={{
                                            fontSize: 18,
                                            color: colors.warning,
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Tienes que poner algo de referencia para poder hacer la búsqueda 🔍🤔.
                                    </Label>
                                </FormText>
                                :
                                null
                        }

                    </FormGroup>
                </Col>
                <Col
                    style={{
                        width: "30%",
                        maxWidth: "30%",
                        minWidth: "30%",
                        textAlign: "right",
                    }}
                >
                    <Tooltip
                        content="Agregar Unidad"
                        style={{
                            borderRadius: 10,
                            backgroundColor: colors.azul_1,
                            padding: 10,
                            color: colors.blanco,
                            fontWeight: "bold",
                            fontFamily: "Pizza Press Fill",
                        }}
                    >
                        <IconButton
                            style={{
                                marginTop: 60,
                                marginRight: 10,
                                backgroundColor: colors.rojo,
                                height: 40,
                                width: 40,
                            }}
                            onClick={async () => {
                                setLabelDataStore("");
                                setStore(StoreService.getStoreStructure());
                                setIsOpenModalDataStore(true);
                                setCitiesList([]);
                            }} >
                            <Add style={{ color: colors.blanco }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        content="Actualizar Tabla"
                        style={{
                            borderRadius: 10,
                            backgroundColor: colors.azul_1,
                            padding: 10,
                            color: colors.blanco,
                            fontWeight: "bold",
                            fontFamily: "Pizza Press Fill",
                        }}
                    >
                        <IconButton
                            style={{
                                marginTop: 60,
                                marginRight: 30,
                                backgroundColor: colors.rojo,
                                height: 40,
                                width: 40,
                            }}
                            onClick={async () => {
                                let IdToast = await toast.loading("Actualizando tabla");
                                if (reference.length === 0) {
                                    await getStoreList()
                                } else {
                                    storeSearch();
                                }
                                await toast.update(IdToast, {
                                    render: 'Tabla actualizada',
                                    isLoading: false,
                                    type: toast.TYPE.INFO,
                                    autoClose: 3000,
                                });
                            }} >
                            <Cached style={{ color: colors.blanco }} />
                        </IconButton>
                    </Tooltip>
                </Col>
            </Row>
            <Block height={10} backgroundColor={colors.blanco} />
            <Row
                style={{
                    marginRight: 0,
                    marginLeft: 0,
                    backgroundColor: colors.blanco,
                    borderRadius: "0px 0px 10px 10px",
                    height: showTextReference ? "77vh" : "82vh",
                    overflowY: "scroll",
                }}
            >
                <Col>
                    <Table
                        title={<Label style={{ fontSize: 28, fontWeight: "bold", }} >{storeList.length} Unidades dadas de alta</Label>}
                        noDataComponent={<label style={{ fontWeight: "bold", fontSize: 24 }}>{labelTable}</label>}
                        columns={columnTableStore}
                        data={storeList}
                    />
                </Col>
            </Row>
            <Modal
                isOpen={isOpenModalDataStore || open}
                size="lg"
                style={{
                    maxWidth: "60%",
                    //marginTop: 
                }}
                onOpened={() => {
                    ComponentTool.focus("UDN");
                }}
            >
                <ModalHeader
                    close={
                        <IconButton
                            style={{ backgroundColor: colors.blanco }}
                            onClick={() => {
                                setLabelDataStore("");
                                setIsOpenModalDataStore(false);
                                setStore(StoreService.getStoreStructure());
                                onOpenChange(false);
                                ComponentTool.focus("reference");
                            }} >

                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul_1,
                    }}
                >
                </ModalHeader>
                <ModalBody
                    style={{
                        overflowY: "scroll",
                        // height: "82vh",
                    }}
                >

                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    UDN
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"UDN"}
                                        name={"UDN"}
                                        value={store.UDN}
                                        onChange={(event) => onChangeStore(event)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Nombre de la Unidad
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"nameStore"}
                                        name={"nameStore"}
                                        value={store.nameStore}
                                        onChange={(event) => onChangeStore(event)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Franquicia
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        type={"select"}
                                        id={"IdFranchise"}
                                        name={"IdFranchise"}
                                        value={store.IdFranchise}
                                        onChange={(event) => onChangeStore(event)}
                                    >
                                        <option value={0}>Escoge una franquicia</option>
                                        <option value={1}>Franquicia A</option>
                                        <option value={2}>Franquicia B</option>
                                        <option value={3}>Franquicia C</option>
                                        <option value={4}>Franquicia D</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Estado
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        type={"select"}
                                        id={"IdState"}
                                        name={"IdState"}
                                        value={store.IdState}
                                        onChange={(event) => {
                                            // onChangeStore(event);
                                            getCitiesByIdStates(event.target.value)
                                            setStore({
                                                ...store,
                                                IdState: event.target.value,
                                                IdCity: 0
                                            });
                                        }}
                                    >
                                        <option value={0}>Escoge una estado</option>
                                        {
                                            stateList.map((state) => {
                                                return (
                                                    <option value={state.IdState} >{state.nameState}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Ciudad
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        type={"select"}
                                        id={"IdCity"}
                                        name={"IdCity"}
                                        value={store.IdCity}
                                        onChange={(event) => onChangeStore(event)}
                                    >
                                        <option value={0}>Escoge una ciudad</option>
                                        {
                                            citiesList.map((city) => {
                                                return (
                                                    <option value={city.IdCity} >{city.nameCity}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Numero Telefónico comercial
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"phone"}
                                        name={"phone"}
                                        value={onFocusPhoneStore ? store.phone : formatNumberPhone(store.phone)}
                                        onChange={(event) => { if (event.target.value.length <= 10) onChangeStore(event); }}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Estatus
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        type={"select"}
                                        id={"IdStatus"}
                                        name={"IdStatus"}
                                        value={store.IdStatus}
                                        onChange={(event) => onChangeStore(event)}
                                    >
                                        <option value={1}>Abierto al publico</option>
                                        <option value={2}>En Contruccion</option>
                                        <option value={3}>Cerrado al publico</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Hora que abre de la unidad
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"timeOpening"}
                                        name={"timeOpening"}
                                        type={"time"}
                                        value={store.timeOpening}
                                        onChange={(event) => onChangeStore(event)}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Hora que cierra de la unidad
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"timeClosing"}
                                        name={"timeClosing"}
                                        type={"time"}
                                        value={store.timeClosing}
                                        onChange={(event) => onChangeStore(event)}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    Dirección
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot",
                                            minHeight: 100,
                                            maxHeight: 100,
                                        }}
                                        type={"textarea"}
                                        id={"address"}
                                        name={"address"}
                                        value={store.address}
                                        onChange={(event) => onChangeStore(event)}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row>
                        {
                            labelDataStore.length === 0 ?
                                null
                                :
                                <Col>
                                    <Label style={{ fontSize: 18, color: colors.amarillo, fontWeight: "bold" }} >
                                        {labelDataStore}
                                    </Label>
                                </Col>
                        }
                        <Col style={{ textAlign: "right" }} >
                            <Button
                                style={{
                                    boxShadow: "none",
                                    backgroundColor: colors.rojo,
                                    borderColor: colors.rojo,
                                    color: colors.blanco,
                                    fontWeight: "bold"
                                }}
                                onClick={() => {
                                    setIsOpenModalMoreDataStore(true);
                                }}
                            >
                                Mapa
                            </Button>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.rojo,
                            borderColor: colors.rojo,
                            color: colors.blanco,
                            fontWeight: "bold",
                            marginRight: 15
                        }}
                        onClick={() => {
                            storeSaveUpdate();
                        }}
                    >
                        {store.IdStore === 0 ? "Guardar" : "Actualizar"}
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={isOpenModalMoreDataStore}
                size="lg"
                style={{
                    maxWidth: "70%",
                    //marginTop: 
                }}
            >
                <ModalHeader
                    close={
                        <IconButton style={{ backgroundColor: colors.blanco }} onClick={() => setIsOpenModalMoreDataStore(false)} >
                            <Close />
                        </IconButton>
                    }
                    style={{
                        backgroundColor: colors.azul_1,
                    }}
                >

                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    URL Google Maps
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"urlGoogleMaps"}
                                        name={"urlGoogleMaps"}
                                        value={store.urlGoogleMaps}
                                        onChange={(event) => onChangeStore(event)}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup style={{ borderRadius: 10, marginTop: 20 }} >
                                <Label style={{ fontWeight: "bold", fontSize: 18 }} >
                                    URL Google Street View
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{
                                            borderColor: `transparent transparent ${colors.azul_2} transparent`,
                                            backgroundColor: colors.gris_1,
                                            boxShadow: "none",
                                            color: colors.azul_2,
                                            borderRadius: 10,
                                            fontFamily: "One Dot"
                                        }}
                                        id={"urlGoogleStreetView"}
                                        name={"urlGoogleStreetView"}
                                        value={store.urlGoogleStreetView}
                                        onChange={(event) => onChangeStore(event)}
                                        onFocus={() => setOnFocusPhoneStore(true)}
                                        onBlur={() => setOnFocusPhoneStore(false)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} >
                        <Col style={{ textAlign: "center" }}>
                            {
                                store.urlGoogleMaps.length === 0 ?
                                    <img
                                        src={mapa}
                                        alt="No se pudo cargar la imagen"
                                        height={250}
                                        styles={{
                                            borderRadius: 10
                                        }}
                                    />
                                    :
                                    <Iframe
                                        src={store.urlGoogleMaps}
                                        width="100%"
                                        height={250}
                                        styles={{
                                            borderRadius: 10
                                        }}
                                    />
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{
                            boxShadow: "none",
                            backgroundColor: colors.rojo,
                            borderColor: colors.rojo,
                            color: colors.blanco,
                            fontWeight: "bold",
                            marginRight: 15
                        }}
                        onClick={() => {
                        }}
                    >
                        Guardar
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );

}

export default StoreAdminList;