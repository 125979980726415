const getDevice = () => {

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isDesktopDevice = !regexp.test(details);
    let isMobileDevice = !isDesktopDevice;

    let device = {
        isDesktopDevice,
        isMobileDevice,
    };

    return device;

};

const getStructureDevice = () => {
    return {
        isDesktopDevice: false,
        isMobileDevice: false,
    };
}

const Device = {
    getDevice,
    getStructureDevice,
};

export default Device;