import colors from "../../../../configs/Colors.config";
import indexMarketing from "../../../../assets/video/indexMarketing.mp4";
import { useEffect, useRef } from "react";

const Index = ({ collapsed }) => {
    const videoRef = useRef(null);



    // useEffect(() => {
    //     playVideo();
    // }, []);
    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };
    return (
        <div
            style={{
                // // width: "100%",
                marginLeft: collapsed ? 80 : 250,
                marginRight: 0,
                height: "100vh",
                marginTop: -25,
                padding: 10,
                fontFamily: "Pizza Press Fill",
                //position: "relative",
            }}
        >
            <section
                style={{
                    width: "100%",
                    height: "95vh",
                    backgroundColor: colors.blanco,
                    borderRadius: 10,
                    padding: 10,
                    margin: 0
                }}
            >
                <video ref={videoRef} autoPlay width="100%" height="100%">
                    <source src={indexMarketing} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                </video>
            </section>
            <button id="btn" style={{}} onClick={playVideo}>Reproducir video</button>
        </div>
    );
}

export default Index;