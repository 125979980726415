
import colors from "../../configs/Colors.config";
import Block from "../../components/Block";
import { Col, Label, Row } from "reactstrap";
import { Zoom, Slide } from "react-reveal";
import { useEffect, useState } from "react";
import TimeTool from "../../Tools/Time.tool";
import { saveAs } from 'file-saver';
import { Compare, GetApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Tooltip } from "@nextui-org/react";
import pizza from "../../assets/images/resources/pizza.jpg";
import logo from "../../assets/images/logo/logo.png";
import backBlanco from "../../assets/images/resources/flags/backBlanco.png";
import redFlag from "../../assets/images/resources/flags/redFlag.png";
import blackFlag from "../../assets/images/resources/flags/blackFlag.png";
import complementos from "../../assets/images/resources/flags/complementos.png";
import papotas from "../../assets/images/resources/foods/papotas.jpg";
import canela from "../../assets/images/resources/foods/canela.jpg";
import cajeta from "../../assets/images/resources/foods/cajeta.jpg";
import cheesy from "../../assets/images/resources/foods/cheesy.jpg";
import volcan from "../../assets/images/resources/foods/volcan.jpg";
import alitas from "../../assets/images/resources/foods/alitas.jpg";
import boneles from "../../assets/images/resources/foods/bolobanes.jpg";
import bebidas from "../../assets/images/resources/foods/bebidas.jpg";
import img01 from "../../assets/images/resources/foods/01.png";
import img02 from "../../assets/images/resources/foods/02.png";
import img03 from "../../assets/images/resources/foods/03.png";
import img04 from "../../assets/images/resources/foods/04.png";

import filePdf from "../../assets/pdf/menu-dominos-gpm.pdf";
import DominosServices from "../../Services/Dominos.service";
import AdditionalServices from "../../Services/Additional.service";

const MenuDominosDesktop = () => {

    const [showLogo, setShowLogo] = useState(false);
    const [showName, setShowName] = useState(false);
    const [isHoverPapotas, setIsHoverPapotas] = useState(false);
    const [isHoverCanela, setIsHoverCanela] = useState(false);
    const [isHoverCajeta, setIsHoverCajeta] = useState(false);
    const [isHoverCheesy, setIsHoverCheesy] = useState(false);
    const [isHoverVolcanes, setIsHoverVolcanes] = useState(false);
    const [isHoverAlitas, setIsHoverAlitas] = useState(false);
    const [isHoverBoneles, setIsHoverBoneles] = useState(false);
    const [isHoverRefrescos, setIsHoverRefrescos] = useState(false);
    const [isHoverButtonDownload, setIsHoverButtonDownload] = useState(false);
    const [additionalList, setAdditionalList] = useState([]);

    const [dominosOriginal, setDominosOriginal] = useState(DominosServices.getDominosPricesStructure(1));
    const [dominosDominator, setDominosDominator] = useState(DominosServices.getDominosPricesStructure(2));
    const [dominosCrunchy, setDominosCrunchy] = useState(DominosServices.getDominosPricesStructure(3));
    const [dominosCheeseShore, setDominosCheeseShore] = useState(DominosServices.getDominosPricesStructure(4));
    const [dominosFrypan, setDominosFrypan] = useState(DominosServices.getDominosPricesStructure(5));
    const [dominosItalian, setDominosItalian] = useState(DominosServices.getDominosPricesStructure(6));

    useEffect(() => {
        loadScreen();
        getPricesDominos();
        loadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPage = async () => {
        let { additionalList } = await AdditionalServices.getAdditionalList();
        setAdditionalList(additionalList);

    }
    const getPricesDominos = async () => {
        let response = await DominosServices.getDominosPriceList(1);
        let price = response.price;
        setDominosOriginal(price);

        response = await DominosServices.getDominosPriceList(2);
        price = response.price;
        setDominosDominator(price);

        response = await DominosServices.getDominosPriceList(3);
        price = response.price;
        setDominosCrunchy(price);

        response = await DominosServices.getDominosPriceList(4);
        price = response.price;
        setDominosCheeseShore(price);

        response = await DominosServices.getDominosPriceList(5);
        price = response.price;
        setDominosFrypan(price);

        response = await DominosServices.getDominosPriceList(6);
        price = response.price;
        setDominosItalian(price);
    }

    const indefiniteComparator = (item) => {        
        return item === undefined ? "--" : item.priceAdditional
    }

    const loadScreen = async () => {
        await TimeTool.sleep(1);
        setShowName(true);
        await TimeTool.sleep(1);
        setShowLogo(true);
    };

    return (
        <>

            <Tooltip
                content="Descargar menu en pdf"
                style={{
                    borderRadius: 10,
                    backgroundColor: colors.rojo,
                    padding: 10,
                    color: colors.blanco,
                    fontWeight: "bold",
                    fontFamily: "Pizza Press Fill",
                }}
            >
                <IconButton
                    style={{
                        position: "fixed",
                        bottom: "20px",
                        right: "20px",
                        backgroundColor: isHoverButtonDownload ? colors.rojo : colors.azul_1,
                        color: colors.blanco,
                        height: 50,
                        width: 50,
                        zIndex: 3
                    }}
                    onMouseEnter={() => {
                        setIsHoverButtonDownload(true);
                    }}
                    onMouseLeave={() => {
                        setIsHoverButtonDownload(false);
                    }}
                    onClick={() => {
                        saveAs(filePdf, 'menu-dominos-gpm.pdf');
                    }}
                >
                    <GetApp style={{ height: 30, width: 30, }} />
                </IconButton >
            </Tooltip>
            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundImage: `url(${pizza})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Block height={"40vh"} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                        fontFamily: "Pizza Press Fill",

                    }}
                >
                    <Col
                        style={{
                            textAlign: "right",
                            maxWidth: "30%",
                            width: "30%",
                        }}
                    >
                        {
                            showLogo ?
                                <Zoom>
                                    <img
                                        src={logo}
                                        alt="No se pudo cargar la imagen"
                                        style={{
                                            width: 150,
                                            marginTop: 30
                                        }}
                                    />
                                </Zoom>
                                :
                                null
                        }
                    </Col>
                    <Col
                        style={{
                            textAlign: "left",
                            maxWidth: "70%",
                            width: "70%",
                        }}
                    >
                        {
                            showName ?
                                <Slide right>
                                    <label
                                        style={{
                                            fontSize: 150,
                                            color: colors.azul_1,
                                            WebkitTextStroke: `3px ${colors.blanco}`,
                                            marginLeft: 10,
                                            transition: 'margin-left 0.3s ease'
                                        }}
                                    >
                                        Domino's GPM
                                    </label>
                                </Slide>
                                :
                                null
                        }
                    </Col>
                </Row>
            </section>

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    fontFamily: "One Dot",
                    backgroundImage: `url(${backBlanco})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Block height={"10vh"} />
                <div
                    style={{
                        border: `4px solid ${colors.azul_1} `,
                        borderRadius: 50,
                        marginRight: "5%",
                        marginLeft: "5%",
                        height: "85vh",
                    }}
                >
                    <div
                        style={{
                            marginTop: -40,
                            border: `4px solid ${"#FF1529"} `,
                            backgroundColor: "#0C3360",
                            marginRight: "30%",
                            marginLeft: "30%",
                            borderRadius: 30,
                            position: "relative",
                            height: 70,
                        }}
                    >
                        <center>

                            <Label
                                style={{
                                    fontSize: 50,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    position: "absolute",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -10,
                                }}
                            >
                                Menú Domino's
                            </Label>
                            <Label
                                style={{
                                    fontSize: 50,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    position: "absolute",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -10,
                                }}
                            >
                                Menú Domino's
                            </Label>

                        </center>
                    </div>
                    <Block height={10} />
                    <Row>
                        <Col>
                            <center>
                                <div
                                    style={{
                                        width: "250px",
                                        height: "50px",
                                        backgroundColor: "#006B99",
                                        clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                    }}
                                >
                                    <Block height={5} />
                                    <Label
                                        style={{
                                            marginLeft: -25,
                                            fontSize: 24,
                                            fontWeight: "bold",
                                            color: colors.blanco,
                                            position: "relative",

                                        }}
                                    >
                                        BÁSICAS

                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 14,
                                            color: colors.blanco,
                                            marginLeft: 5,
                                            marginTop: 10,
                                            position: "absolute"
                                        }}
                                    >
                                        (1 ING.)
                                    </Label>
                                </div>
                            </center>
                        </Col>
                    </Row>
                    <Block height={10} />
                    <Row>
                        <center>
                            <p
                                style={{
                                    lineHeight: 1.1,
                                    fontSize: 18,
                                    letterSpacing: 2,
                                    fontWeight: "bold",
                                    color: "#0C3360",
                                }}
                            >
                                Peperoni, Jamón, Tocino, Salami, Chorizo, Carne Molida, Pollo,
                                <br />
                                Champiñones Frescos, Piña, Jalapeños, Aceitunas, Cebolla y Pimiento
                            </p>
                        </center>
                    </Row>
                    <Block height={5} />
                    <div style={{ marginRight: "3%", marginLeft: "3%", height: 3, backgroundColor: "#006B99", }} > </div>
                    <Block height={10} />
                    <Row>
                        <Col style={{ lineHeight: 1.1, }} >
                            <center>
                                <Label
                                    style={{
                                        fontSize: 24,
                                        color: "#FF1529",
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        WebkitTextStroke: `1px ${"#FF1529"}`,
                                        letterSpacing: 2,
                                    }}
                                >
                                    Especialidades
                                </Label>
                                <br />
                                <div
                                    style={{
                                        width: "250px",
                                        height: "50px",
                                        backgroundColor: "#FF1529",
                                        clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                    }}
                                >
                                    <Block height={10} />
                                    <Label
                                        style={{
                                            // marginLeft: -25,
                                            fontSize: 24,
                                            fontWeight: "bold",
                                            color: colors.blanco,
                                            position: "relative",

                                        }}
                                    >
                                        FAVORITAS
                                    </Label>
                                </div>
                            </center>
                        </Col>
                    </Row>
                    <Block height={10} />
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            lineHeight: 1,
                            fontFamily: "Pizza Press Fill",
                        }}
                    >
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                HAWAIANA
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold"
                                }}
                            >
                                Jamón y piña.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                PEPPERONI ESPECIAL
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Pepperoni y champiñones <br /> frescos.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                MEXICANA
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Chorizo, carne molida, <br /> cebolla y jalapeño.
                            </Label>
                        </Col>

                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                VEGGIE
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold"
                                }}
                            >
                                Pimiento, cebolla, <br />
                                champiñones y aceitunas.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                HONOLULU
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Jamón, piña, tocino<br />y jalapeño.
                            </Label>
                        </Col>
                    </Row>
                    <div style={{ marginRight: "3%", marginLeft: "3%", height: 3, backgroundColor: "#006B99", }} > </div>
                    <Block height={10} />
                    <Row>
                        <Col style={{ lineHeight: 1.1, }} >
                            <center>
                                <Label
                                    style={{
                                        fontSize: 24,
                                        color: colors.negro,
                                        fontFamily: "Pizza Press Reverse Shadow",
                                        WebkitTextStroke: `1px ${colors.negro}`,
                                        letterSpacing: 2,
                                    }}
                                >
                                    Especialidades
                                </Label>
                                <br />
                                <div
                                    style={{
                                        width: "250px",
                                        height: "50px",
                                        backgroundColor: colors.negro,
                                        clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                    }}
                                >
                                    <Block height={10} />
                                    <Label
                                        style={{
                                            // marginLeft: -25,
                                            fontSize: 24,
                                            fontWeight: "bold",
                                            color: colors.blanco,
                                            position: "relative",

                                        }}
                                    >
                                        Premium
                                    </Label>
                                </div>
                            </center>
                        </Col>
                    </Row>
                    <Block height={10} />
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            lineHeight: 1,
                            fontFamily: "Pizza Press Fill",
                        }}
                    >
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                CHICKEN HAWAIANA
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold"
                                }}
                            >
                                Pollo, tocino, piña y salsa de <br />
                                mango habanero.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                CARNES FRIíS
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Pepperoni, salami, jamón<br />y finas hierbas.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                4 QUESOS
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Queso mozzarella, queso crema,<br />queso cheddar, queso parmesano.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                TEXAS BBQ
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold"
                                }}
                            >
                                Carne molida, tocino, pollo,<br />
                                salsa BBQ.
                            </Label>
                        </Col>
                    </Row>
                    <Block height={10} />
                    <Row
                        style={{
                            marginRight: "5%",
                            marginLeft: "5%",
                            lineHeight: 1,
                            fontFamily: "Pizza Press Fill",
                        }}
                    >

                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                CHESSY BBQ
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Base salsa BBQ, cebolla, pollo,<br />jalapeño, queso chedar
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                DELUXE
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Pepperoni, carne molida,<br />champiñones, pimiento y cebolla.
                            </Label>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 24,
                                    color: "#006F9E",
                                }}
                            >
                                EXTRAVAGANZZA
                            </Label>
                            <br />
                            <Label
                                style={{
                                    fontSize: 16,
                                    fontFamily: "One Dot",
                                    fontWeight: "bold",
                                    lineHeight: 1,
                                }}
                            >
                                Pepperoni, carne molida, jamón, pimiento<br />cebolla, champiñones y aceitunas
                            </Label>
                        </Col>
                    </Row>
                </div>
                <Block height={"5vh"} />
            </section >

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    fontFamily: "One Dot",
                    backgroundImage: `url(${backBlanco})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Block height={"10vh"} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                    }}
                >
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={redFlag}
                            alt="No se pudo cargar la imagen"
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                    }}
                >
                    <Col style={{ marginRight: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        original
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        original
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosOriginal.priceBasicMedium}
                                    </Col>
                                    <Col>
                                        ${dominosOriginal.priceBasicBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosOriginal.priceFavoriteMedium}
                                    </Col>
                                    <Col>
                                        ${dominosOriginal.priceFavoriteBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000000" }} >
                                    <Col>
                                        ${dominosOriginal.pricePremiumMedium}
                                    </Col>
                                    <Col>
                                        ${dominosOriginal.pricePremiumBig}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    Masa fresca hecha a mano al momento.
                                </Label>
                            </Col>
                        </Row>
                        <div style={{ height: 3, backgroundColor: "#FF1529", width: "90%", marginRight: "5%", marginLeft: "5%" }} > </div>
                    </Col>
                    <Col style={{ marginLeft: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "40%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        DOMINATOR
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        DOMINATOR
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosDominator.priceBasicBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosDominator.priceFavoriteBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        ${dominosDominator.pricePremiumBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    ¡Nuestra Dominos mas grande! con 45 cm de diámetro, hecha al momento.
                                </Label>
                            </Col>
                        </Row>
                        <div style={{ height: 3, backgroundColor: "#FF1529", width: "90%", marginRight: "5%", marginLeft: "5%" }} > </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                        marginTop: 50,
                    }}
                >
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={blackFlag}
                            alt="No se pudo cargar la imagen"
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                    }}
                >
                    <Col style={{ marginRight: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        crunchy
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        crunchy
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosCrunchy.priceBasicBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosCrunchy.priceFavoriteBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        ${dominosCrunchy.pricePremiumBig}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    Masa delgada y crujiente  estilo italiano.
                                </Label>
                            </Col>
                        </Row>
                        <div style={{ height: 3, backgroundColor: "#FF1529", width: "90%", marginRight: "5%", marginLeft: "5%" }} > </div>
                    </Col>
                    <Col style={{ marginLeft: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "45%",
                                        height: 50,
                                        position: "relative",
                                        zIndex: 1
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        orilla rellena
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        orilla rellena
                                    </Label>
                                </div>
                                <Label
                                    style={{
                                        zIndex: 2,
                                        fontFamily: "Agbalumo",
                                        marginTop: -50,
                                        fontSize: 36,
                                        position: "relative",
                                        WebkitTextStroke: `1px #0C3360`,
                                        color: "#FFFFFF",
                                        fontWeight: "bold"
                                    }}
                                >
                                    de Queso
                                </Label>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: -15,
                                fontWeight: "bold",
                                fontSize: 20
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosCheeseShore.priceBasicMedium}
                                    </Col>
                                    <Col>
                                        ${dominosCheeseShore.priceBasicBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosCheeseShore.priceFavoriteMedium}
                                    </Col>
                                    <Col>
                                        ${dominosCheeseShore.priceFavoriteBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        ${dominosCheeseShore.pricePremiumMedium}
                                    </Col>
                                    <Col>
                                        ${dominosCheeseShore.pricePremiumBig}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    Masa fresca hecha a mano con orilla de queso 100% mozzarella.
                                </Label>
                            </Col>
                        </Row>
                        <div style={{ height: 3, backgroundColor: "#FF1529", width: "100%", marginRight: "0%", marginLeft: "0%", }} > </div>
                    </Col>
                </Row>

            </section>

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    fontFamily: "One Dot",
                    backgroundImage: `url(${backBlanco})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Block height={"10vh"} />
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                    }}
                >
                    <Col style={{ marginRight: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "40%",
                                        height: 50,
                                        position: "relative",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        pizza de sartén
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        pizza de sartén
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40,
                                    }}
                                >
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        MEDIANA
                                    </Col>
                                    <Col>
                                        GRANDE
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosFrypan.priceBasicMedium}
                                    </Col>
                                    <Col>
                                        ${dominosFrypan.priceBasicBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosFrypan.priceFavoriteMedium}
                                    </Col>
                                    <Col>
                                        ${dominosFrypan.priceFavoriteBig}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        ${dominosFrypan.pricePremiumMedium}
                                    </Col>
                                    <Col>
                                        ${dominosFrypan.pricePremiumBig}
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    Masa dorada en sartén, doble capa de queso con ingredientes hasta la orilla.
                                </Label>
                            </Col>
                        </Row>
                        <Block height={20} />
                    </Col>
                    <Col style={{ marginLeft: 25 }} >
                        <Row>
                            <center>
                                <div
                                    style={{
                                        border: `4px solid ${"#FF1529"} `,
                                        backgroundColor: "#0C3360",
                                        borderRadius: 30,
                                        width: "30%",
                                        height: 50,
                                        position: "relative",
                                        zIndex: 1
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#00A0DB",
                                            fontFamily: "Pizza Press Reverse Shadow",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                        }}
                                    >
                                        italiana
                                    </Label>
                                    <Label
                                        style={{
                                            fontSize: 36,
                                            color: "#FFFFFF",
                                            fontFamily: "Pizza Press Fill",
                                            zIndex: 2,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            marginTop: -9,
                                            position: "absolute"
                                        }}
                                    >
                                        italiana
                                    </Label>
                                </div>
                            </center>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontWeight: "bold",
                                fontSize: 24
                            }}
                        >
                            <Col>
                                <Label>
                                    BÁSICA
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    FAVORITAS
                                </Label>
                            </Col>
                            <Col>
                                <Label>
                                    PREMIUM
                                </Label>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                marginTop: 15,
                                fontSize: 18,
                                fontWeight: "bold"
                            }}
                        >
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        height: 40
                                    }}
                                >
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        EXTRA GRANDE
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Row
                            style={{
                                textAlign: "center",
                                fontSize: 32,
                                fontFamily: "Pizza Press Fill",
                                color: colors.blanco,
                            }}
                        >
                            <Col>
                                <Row style={{ backgroundColor: "#00A0DB" }} >
                                    <Col>
                                        ${dominosItalian.priceBasicBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row
                                    style={{
                                        borderRight: "2px solid #000",
                                        borderLeft: "2px solid #000",
                                        backgroundColor: "#FF1529",
                                    }}
                                >
                                    <Col>
                                        ${dominosItalian.priceFavoriteBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ backgroundColor: "#000" }} >
                                    <Col>
                                        ${dominosItalian.pricePremiumBigExtra}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Col style={{ textAlign: "center", fontWeight: "bold" }} >
                                <Label>
                                    Masa fresca delgadita, hecha a mano con queso mozzarella y provolone y un toque de albahaca 40cm de diámetro, hecho al momento.
                                </Label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* <div style={{ height: 3, backgroundColor: "#FF1529", width: "30%", marginRight: "35%", marginLeft: "35%" }} > </div> */}
                {/* <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                        marginTop: 10
                    }}
                >
                    <Col
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            lineHeight: '1',
                        }}
                    >
                        <Label>
                            MEDIANA 30 cm aprox. GRANDES 35cm aprox.
                        </Label>
                        <br />
                        <Label>
                            EXTRA GRANDE 40 cm y 45 cm aprox.
                        </Label>
                    </Col>
                </Row> */}
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                        marginTop: -10
                    }}
                >
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={complementos}
                            alt="No se pudo cargar la imagen"
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                        height: 180
                    }}
                >
                    <div id="carousel" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col" >
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverPapotas(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverPapotas(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={papotas}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverPapotas ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverPapotas ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Gajos de papa horneada, con un toque picoso, acompañados con queso y salsa brava (164 g)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            PAPOTAS <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[0])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverCanela(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverCanela(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={canela}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverCanela ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverCanela ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Bocados horneados, espolvoreados con canela y azúcar acompañados con un dip de sabor cappuccino (16piezas)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            CANELA BAITZ <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[1])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverCajeta(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverCajeta(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={cajeta}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverCajeta ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverCajeta ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Panecitos cubiertos con cajeta y glass (16 piezas)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            CAJETA BAITZ <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[2])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverCheesy(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverCheesy(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={cheesy}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverCheesy ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverCheesy ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 18
                                                            }}
                                                        >
                                                            Pan relleno de queso crema, mozzarella con u toque de finas hierbas, chorizo, jalapeños, gratinado con queso cheddar,
                                                            mozzarella y parmesano (8 tiras)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            CHEESY BREAD <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[3])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverVolcanes(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverVolcanes(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={volcan}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverVolcanes ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverVolcanes ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 18
                                                            }}
                                                        >
                                                            Delicioso pastel orneado, crujiente por fuera, con irresistible sabor chocolate derretido por dentro, elaborado con galleta Oreo (2 piezas)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            VOLCÁN DE CHOCOLATE <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[4])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverAlitas(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverAlitas(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={alitas}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverAlitas ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverAlitas ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Horneadas con 1 salsa a elegir: Cayenne, Mango Habanero o BBQ (200g)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            ALITAS <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[5])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverBoneles(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverBoneles(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={boneles}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverBoneles ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverBoneles ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Horneadas con 1 salsa a elegir: Cayenne, Mango Habanero o BBQ (168g)
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            BONELESS <br /> <label style={{ fontSize: 46 }} > ${indefiniteComparator(additionalList[6])} </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div
                                            onMouseEnter={() => {
                                                setIsHoverRefrescos(true);
                                            }}
                                            onMouseLeave={() => {
                                                setIsHoverRefrescos(false);
                                            }}
                                            style={{
                                                position: 'relative',
                                                display: 'inline-block',
                                                width: "100%",
                                            }}
                                        >
                                            <img
                                                alt="No se pudo cargar la imagen"
                                                src={bebidas}
                                                style={{
                                                    width: '100%',
                                                    height: 150,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: '#FF1529',
                                                    height: 150,
                                                    position: 'relative',
                                                    ...isHoverRefrescos ? { transform: 'scaleY(1.5)' } : {},
                                                    transition: 'transform 0.3s ease',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: colors.blanco,
                                                    textAlign: "center"

                                                }}
                                            >
                                                {
                                                    isHoverRefrescos ?
                                                        <p
                                                            style={{
                                                                lineHeight: 1.2,
                                                                padding: 10,
                                                                fontSize: 22
                                                            }}
                                                        >
                                                            Consulta tamaños, presentaciones y disponibilidad en Tienda
                                                        </p>
                                                        :
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: 26
                                                            }}
                                                        >
                                                            BEBIDAS <br /> <label style={{ fontSize: 46 }} >  </label>
                                                        </Label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="sr-only">Next</span>
                        </a>
                    </div>

                </Row>
            </section>

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    fontFamily: "One Dot",
                    backgroundImage: `url(${backBlanco})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Block height={"10vh"} />
                <Row
                    style={{
                        marginRight: 50,
                        marginLeft: 50,
                    }}
                >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img01}
                            alt="No se pudo cargar la imagen"
                            style={{
                                height: "80vh"
                            }}
                        />
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img02}
                            alt="No se pudo cargar la imagen"
                            style={{
                                height: "80vh",
                            }}
                        />
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img03}
                            alt="No se pudo cargar la imagen"
                            style={{
                                height: "80vh",
                            }}
                        />
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img04}
                            alt="No se pudo cargar la imagen"
                            style={{
                                height: "80vh",
                            }}
                        />
                    </Col>
                </Row>

            </section>

        </>
    );

}

export default MenuDominosDesktop;