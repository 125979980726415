import { saveAs } from 'file-saver';
import { GetApp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import colors from "../../configs/Colors.config";
import filePdf from "../../assets/pdf/menu-dominos-gpm.pdf";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Label, Row } from "reactstrap";
import { Zoom } from "react-reveal";
import Block from '../../components/Block';



import backBlancoMovile from "../../assets/images/resources/backBlancoMovile.png";
import logo from "../../assets/images/logo/logo.png";
import pizza from "../../assets/images/resources/pizza.jpg";
import redFlag from "../../assets/images/resources/flags/redFlag.png";
import blackFlag from "../../assets/images/resources/flags/blackFlag.png";
import complementos from "../../assets/images/resources/flags/complementos.png";

import papotas from "../../assets/images/resources/foods/papotas.jpg";
import canela from "../../assets/images/resources/foods/canela.jpg";
import cajeta from "../../assets/images/resources/foods/cajeta.jpg";
import cheesy from "../../assets/images/resources/foods/cheesy.jpg";
import volcan from "../../assets/images/resources/foods/volcan.jpg";
import alitas from "../../assets/images/resources/foods/alitas.jpg";
import boneles from "../../assets/images/resources/foods/bolobanes.jpg";
import bebidas from "../../assets/images/resources/foods/bebidas.jpg";

import img01 from "../../assets/images/resources/foods/01.png";
import img02 from "../../assets/images/resources/foods/02.png";
import img03 from "../../assets/images/resources/foods/03.png";
import img04 from "../../assets/images/resources/foods/04.png";
import { useState } from 'react';
import DominosServices from '../../Services/Dominos.service';
import { useEffect } from 'react';

const MenuDominosMovile = () => {
    const [dominosOriginal, setDominosOriginal] = useState(DominosServices.getDominosPricesStructure(1));
    const [dominosDominator, setDominosDominator] = useState(DominosServices.getDominosPricesStructure(2));
    const [dominosCrunchy, setDominosCrunchy] = useState(DominosServices.getDominosPricesStructure(3));
    const [dominosCheeseShore, setDominosCheeseShore] = useState(DominosServices.getDominosPricesStructure(4));
    const [dominosFrypan, setDominosFrypan] = useState(DominosServices.getDominosPricesStructure(5));
    const [dominosItalian, setDominosItalian] = useState(DominosServices.getDominosPricesStructure(6));

    useEffect(() => {
        getPricesDominos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPricesDominos = async () => {
        let response = await DominosServices.getDominosPriceList(1);
        let price = response.price;
        setDominosOriginal(price);

        response = await DominosServices.getDominosPriceList(2);
        price = response.price;
        setDominosDominator(price);

        response = await DominosServices.getDominosPriceList(3);
        price = response.price;
        setDominosCrunchy(price);

        response = await DominosServices.getDominosPriceList(4);
        price = response.price;
        setDominosCheeseShore(price);

        response = await DominosServices.getDominosPriceList(5);
        price = response.price;
        setDominosFrypan(price);

        response = await DominosServices.getDominosPriceList(6);
        price = response.price;
        setDominosItalian(price);
    }

    return (
        <>
            <IconButton
                style={{
                    position: "fixed",
                    bottom: "40px",
                    right: "30px",
                    backgroundColor: colors.azul_1,
                    color: colors.blanco,
                    height: 50,
                    width: 50,
                    zIndex: 3
                }}
                onClick={() => {
                    saveAs(filePdf, 'menu-dominos-gpm.pdf');
                }}
            >
                <GetApp style={{ height: 35, width: 35, }} />
            </IconButton >

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundImage: `url(${pizza})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    fontFamily: "Pizza Press Fill",
                }}
            >
                <Zoom>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <img
                                src={logo}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    width: 200,
                                    marginTop: 50
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 50, marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <label
                                style={{
                                    fontSize: 60,
                                    color: colors.azul_1,
                                    WebkitTextStroke: `2px ${colors.blanco}`,
                                    fontWeight: "bold",
                                    marginLeft: 0,
                                    transition: 'margin-left 0.3s ease'
                                }}
                            >
                                Domino's GPM
                            </label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5, marginRight: 10, marginLeft: 10, }} >
                        <Col style={{ textAlign: "center" }} >
                            <label
                                style={{
                                    fontSize: 28,
                                    color: colors.azul_1,
                                    WebkitTextStroke: `1px ${colors.blanco}`,
                                    fontWeight: "bold",
                                    marginLeft: 0,
                                    fontFamily: "Pizza Press Fill"
                                }}
                            >
                                Disfruta de nuestras dominos en tu tienda mas cercana
                            </label>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10, marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <Button
                                style={{
                                    fontSize: 28,
                                    backgroundColor: colors.rojo,
                                    color: colors.blanco,
                                    borderColor: colors.rojo,
                                    boxShadow: "none",
                                }}
                                onClick={() => {
                                    window.location.href = "/"
                                }}
                            >
                                Ver tiendas
                            </Button>
                        </Col>
                    </Row>
                </Zoom>
            </section>

            <section
                style={{
                    width: "100%",
                    height: "auto",
                    backgroundImage: `url(${backBlancoMovile})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    fontFamily: "Pizza Press Fill",
                }}
            >
                <Block height={"7vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ textAlign: "center" }} >
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                // marginRight: "30%",
                                // marginLeft: "30%",
                                borderRadius: 30,
                                position: "relative",
                                height: 70,
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 50,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    position: "absolute",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -10,
                                }}
                            >
                                Menú Domino's
                            </Label>
                            <Label
                                style={{
                                    fontSize: 50,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    position: "absolute",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -10,
                                }}
                            >
                                Menú Domino's
                            </Label>
                        </div>
                    </Col>
                </Row>
                <Block height={30} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col>
                        <center>
                            <div
                                style={{
                                    width: "250px",
                                    height: "50px",
                                    backgroundColor: "#006B99",
                                    clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                }}
                            >
                                <Block height={5} />
                                <Label
                                    style={{
                                        marginLeft: -25,
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        position: "relative",

                                    }}
                                >
                                    BÁSICAS

                                </Label>
                                <Label
                                    style={{
                                        fontSize: 14,
                                        color: colors.blanco,
                                        marginLeft: 5,
                                        marginTop: 10,
                                        position: "absolute"
                                    }}
                                >
                                    (1 ING.)
                                </Label>
                            </div>
                        </center>
                    </Col>
                </Row>
                <Block height={10} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <center>
                        <p
                            style={{
                                lineHeight: 1.1,
                                fontSize: 18,
                                letterSpacing: 2,
                                fontWeight: "bold",
                                color: "#0C3360",
                            }}
                        >
                            Peperoni, Jamón, Tocino, Salami, Chorizo, Carne Molida, Pollo,
                            <br />
                            Champiñones Frescos, Piña, Jalapeños, Aceitunas, Cebolla y Pimiento
                        </p>
                    </center>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ lineHeight: 1.1, }} >
                        <center>
                            <Label
                                style={{
                                    fontSize: 24,
                                    color: "#FF1529",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    WebkitTextStroke: `1px ${"#FF1529"}`,
                                    letterSpacing: 2,
                                }}
                            >
                                Especialidades
                            </Label>
                            <br />
                            <div
                                style={{
                                    width: "250px",
                                    height: "50px",
                                    backgroundColor: "#FF1529",
                                    clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                }}
                            >
                                <Block height={10} />
                                <Label
                                    style={{
                                        // marginLeft: -25,
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        position: "relative",

                                    }}
                                >
                                    FAVORITAS
                                </Label>
                            </div>
                        </center>
                    </Col>
                </Row>
                <Block height={10} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Block height={10} />
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            HAWAIANA
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold"
                            }}
                        >
                            Jamón y piña.
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            PEPPERONI ESPECIAL
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Pepperoni y champiñones <br /> frescos.
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            MEXICANA
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Chorizo, carne molida, <br /> cebolla y jalapeño.
                        </Label>
                    </Col>

                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            VEGGIE
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold"
                            }}
                        >
                            Pimiento, cebolla, <br />
                            champiñones y aceitunas.
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            HONOLULU
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Jamón, piña, tocino<br />y jalapeño.
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ lineHeight: 1.1, }} >
                        <center>
                            <Label
                                style={{
                                    fontSize: 24,
                                    color: colors.negro,
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    WebkitTextStroke: `1px ${colors.negro}`,
                                    letterSpacing: 2,
                                }}
                            >
                                Especialidades
                            </Label>
                            <br />
                            <div
                                style={{
                                    width: "250px",
                                    height: "50px",
                                    backgroundColor: colors.negro,
                                    clipPath: "polygon(0 4%, 100% 0, 96% 96%, 6% 100%)",
                                }}
                            >
                                <Block height={10} />
                                <Label
                                    style={{
                                        // marginLeft: -25,
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        position: "relative",

                                    }}
                                >
                                    Premium
                                </Label>
                            </div>
                        </center>
                    </Col>
                </Row>
                <Block height={10} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            CHICKEN HAWAIANA
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold"
                            }}
                        >
                            Pollo, tocino, piña y salsa de <br />
                            mango habanero.
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            CARNES FRIíS
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Pepperoni, salami, jamón<br />y finas hierbas.
                        </Label>
                    </Col>

                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            4 QUESOS
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Queso mozzarella, queso crema,<br />queso cheddar, queso parmesano.
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            TEXAS BBQ
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold"
                            }}
                        >
                            Carne molida, tocino, pollo,<br />
                            salsa BBQ.
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, }} >

                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            CHESSY BBQ
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Base salsa BBQ, cebolla, pollo,<br />jalapeño, queso chedar
                        </Label>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            DELUXE
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Pepperoni, carne molida,<br />champiñones, pimiento y cebolla.
                        </Label>
                    </Col>

                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#006F9E",
                            }}
                        >
                            EXTRAVAGANZZA
                        </Label>
                        <br />
                        <Label
                            style={{
                                fontSize: 16,
                                fontFamily: "One Dot",
                                fontWeight: "bold",
                                lineHeight: 1,
                            }}
                        >
                            Pepperoni, carne molida, jamón, pimiento<br />cebolla, champiñones y aceitunas
                        </Label>
                    </Col>
                </Row>
                <Block height={10} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={redFlag}
                            alt="No se pudo cargar la imagen"
                            width={"100%"}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                original
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                original
                            </Label>
                        </div>
                    </center>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>MEDIANA</td>
                                <td>GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td>BÁSICA</td>
                                <td>${dominosOriginal.priceBasicMedium}</td>
                                <td>${dominosOriginal.priceBasicBig}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }}  >
                                <td >FAVORITAS</td>
                                <td>${dominosOriginal.priceFavoriteMedium}</td>
                                <td>${dominosOriginal.priceFavoriteBig}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td>PREMIUM</td>
                                <td>${dominosOriginal.pricePremiumMedium}</td>
                                <td>${dominosOriginal.pricePremiumBig}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                dominator
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                dominator
                            </Label>
                        </div>
                    </center>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>EXTRA GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td >BÁSICA</td>
                                <td>${dominosDominator.priceBasicBigExtra}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }} >
                                <td  >FAVORITAS</td>
                                <td>${dominosDominator.priceFavoriteBigExtra}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td  >PREMIUM</td>
                                <td>${dominosDominator.pricePremiumBigExtra}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={blackFlag}
                            alt="No se pudo cargar la imagen"
                            width={"100%"}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                crunchy
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                crunchy
                            </Label>
                        </div>
                    </center>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td  >BÁSICA</td>
                                <td>${dominosCrunchy.priceBasicBig}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }} >
                                <td  >FAVORITAS</td>
                                <td>${dominosCrunchy.priceFavoriteBig}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td  >PREMIUM</td>
                                <td>${dominosCrunchy.pricePremiumBig}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                                zIndex: 1
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                orilla rellena
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                orilla rellena
                            </Label>
                        </div>
                        <Label
                            style={{
                                zIndex: 2,
                                fontFamily: "Agbalumo",
                                marginTop: -50,
                                fontSize: 36,
                                position: "relative",
                                WebkitTextStroke: `1px #0C3360`,
                                color: "#FFFFFF",
                                fontWeight: "bold"
                            }}
                        >
                            de Queso
                        </Label>
                    </center>
                </Row>
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>MEDIANA</td>
                                <td>GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td  >BÁSICA</td>
                                <td>${dominosCheeseShore.priceBasicMedium}</td>
                                <td>${dominosCheeseShore.priceBasicBig}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }} >
                                <td  >FAVORITAS</td>
                                <td>${dominosCheeseShore.priceFavoriteMedium}</td>
                                <td>${dominosCheeseShore.priceFavoriteBig}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td  >PREMIUM</td>
                                <td>${dominosCheeseShore.pricePremiumMedium}</td>
                                <td>${dominosCheeseShore.pricePremiumBig}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                Pizza de Sartén
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                Pizza de Sartén
                            </Label>
                        </div>
                    </center>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>MEDIANA</td>
                                <td>GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td>BÁSICA</td>
                                <td>${dominosFrypan.priceBasicMedium}</td>
                                <td>${dominosFrypan.priceBasicBig}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }}  >
                                <td >FAVORITAS</td>
                                <td>${dominosFrypan.priceFavoriteMedium}</td>
                                <td>${dominosFrypan.priceFavoriteBig}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td>PREMIUM</td>
                                <td>${dominosFrypan.pricePremiumMedium}</td>
                                <td>${dominosFrypan.pricePremiumBig}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }}>
                    <center>
                        <div
                            style={{
                                border: `4px solid ${"#FF1529"} `,
                                backgroundColor: "#0C3360",
                                borderRadius: 30,
                                width: "80%",
                                height: 50,
                                position: "relative",
                            }}
                        >
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#00A0DB",
                                    fontFamily: "Pizza Press Reverse Shadow",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                }}
                            >
                                italiana
                            </Label>
                            <Label
                                style={{
                                    fontSize: 36,
                                    color: "#FFFFFF",
                                    fontFamily: "Pizza Press Fill",
                                    zIndex: 2,
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    marginTop: -9,
                                    position: "absolute"
                                }}
                            >
                                italiana
                            </Label>
                        </div>
                    </center>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 10, marginLeft: 10, fontSize: 28, textAlign: "center" }}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>GRANDE</td>
                            </tr>
                        </thead>
                        <thead>
                            <tr style={{ color: colors.blanco, backgroundColor: "#00A0DB" }} >
                                <td  >BÁSICA</td>
                                <td>${dominosItalian.priceBasicBig}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ color: colors.blanco, backgroundColor: "#FF1529" }} >
                                <td  >FAVORITAS</td>
                                <td>${dominosItalian.priceFavoriteBig}</td>
                            </tr>
                            <tr style={{ color: colors.blanco, backgroundColor: "#000000" }} >
                                <td  >PREMIUM</td>
                                <td>${dominosItalian.pricePremiumBig}</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <img
                            src={complementos}
                            alt="No se pudo cargar la imagen"
                            style={{
                                width: "100%"
                            }}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        Papotas
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $79
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={papotas}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Gajos de papa horneada, con un toque picoso, acompañados con queso y salsa brava (164 g)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        CANELA BAITZ
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $79
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={canela}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Bocados horneados, espolvoreados con canela y azúcar acompañados con un dip de sabor cappuccino (16piezas)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        CAJETA BAITZ
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $79
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={cajeta}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Panecitos cubiertos con cajeta y glass (16 piezas)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        CHEESY BREAD
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $99
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={cheesy}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Pan relleno de queso crema, mozzarella con u toque de finas hierbas, chorizo, jalapeños, gratinado con queso cheddar,
                                        mozzarella y parmesano (8 tiras)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        VOLCÁN DE CHOCOLATE
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $99
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={volcan}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Delicioso pastel orneado, crujiente por fuera, con irresistible sabor chocolate derretido por dentro, elaborado con galleta Oreo (2 piezas)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        ALITAS
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $129
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={alitas}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Horneadas con 1 salsa a elegir: Cayenne, Mango Habanero o BBQ (200g)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        BONELESS
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>
                                        $129
                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={boneles}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Horneadas con 1 salsa a elegir: Cayenne, Mango Habanero o BBQ (168g)
                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>
                <Block height={20} />
                <Row style={{ marginRight: 0, marginLeft: 0, }} >
                    <Col >
                        <center>
                            <Card
                                style={{
                                    width: '90%',
                                    textAlign: "justify"
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h1" style={{ color: colors.rojo }}>
                                        BEBIDAS
                                    </CardTitle>
                                    <CardSubtitle tag="h2" style={{ color: colors.rojo }}>

                                    </CardSubtitle>
                                </CardBody>
                                <img
                                    alt="No se pudo cargar la imagen"
                                    src={bebidas}
                                    width="100%"
                                />
                                <CardBody>
                                    <CardText tag="h5" style={{ color: colors.azul_1 }}>
                                        Consulta tamaños, presentaciones y disponibilidad en Tienda                                    </CardText>
                                </CardBody>
                            </Card>
                        </center>
                    </Col>
                </Row>

                <Block height={20} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                    }}
                >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img01}
                            alt="No se pudo cargar la imagen"
                            style={{
                                width: "90%"
                            }}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                    }}
                >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img02}
                            alt="No se pudo cargar la imagen"
                            style={{
                                width: "90%"
                            }}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                    }}
                >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img03}
                            alt="No se pudo cargar la imagen"
                            style={{
                                width: "90%"
                            }}
                        />
                    </Col>
                </Row>
                <Block height={20} />
                <Row
                    style={{
                        marginRight: 0,
                        marginLeft: 0,
                    }}
                >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={img04}
                            alt="No se pudo cargar la imagen"
                            style={{
                                width: "90%"
                            }}
                        />
                    </Col>
                </Row>
            </section>
        </>
    );

}

export default MenuDominosMovile;