const Text = ({ text, fontSize, transform, transition, style = {} }) => {
    return (
        <div>
            <label
                style={{
                    ...style,
                    fontSize: fontSize === undefined ? "" : fontSize,
                    color: "#00283F",
                    fontFamily: "Pizza Press Reverse Shadow",
                    position: "absolute",
                    transform: transform === undefined ? '' : 'translateY(-2px) translateX(2px)',
                    transition: transform === undefined ? '' : 'background-color 0.9s, transform 0.9s ease-in-out',
                }}
            >
                {text}
            </label>
            <label
                style={{
                    ...style,
                    fontSize: fontSize === undefined ? "" : fontSize,
                    color: "#FFFFFF",
                    fontFamily: "Pizza Press Fill",
                    transform: transform === undefined ? '' : 'translateY(-2px) translateX(2px)',
                    transition: transform === undefined ? '' : 'background-color 0.9s, transform 0.9s ease-in-out',
                }}
            >
                {text}
            </label>
        </div >
    )
}

export default Text;