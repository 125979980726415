import { BounceLoader } from 'react-spinners';
import { Col, Row } from 'reactstrap';

const LoadPage = () => {

    return (

        <section
            style={{
                width: "100%",
                height: "100vh",
                backgroundImage: "linear-gradient(to bottom, #006491, #016998, #026e9e, #0473a5, #0578ac, #067cb1, #067fb6, #0783bb, #0786bf, #0789c4, #078dc8, #0790cd)",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <Row style={{ marginRight: 0, marginLeft: 0 }} >
                <Col style={{ textAlign: "center" }} >
                    <BounceLoader
                        color="#FFFF"
                        loading
                        size={200}

                    />
                </Col>

            </Row>
        </section>

    );
};

export default LoadPage;
