import axios from "axios";
import UserMiddleware from "../middleware/User.middleware"
import Swal from "sweetalert2";
import colors from "../configs/Colors.config";
import ServiceConfig from "../configs/Service.config"

const url = ServiceConfig.getUrlApiAdminMarketing() + "/additional";

const messageError = (messageError) => {
    console.error(messageError);
    Swal.fire({
        icon: "error",
        title: "Error",
        text: "❌ ¡Algo ha salido mal! Por favor, 🔄 intenta más tarde o 📧 comunícate con el soporte técnico. 😞🔧🚫",
        footer: messageError,
        confirmButtonColor: colors.azul_1
    });
}

const getAdditionalStructure = () => {
    const additional = {
        IdAdditional: 0,
        nameAdditional: "",
        descriptionAdditional: "",
        priceAdditional: 0,
        img: ""
    };

    return additional;
}


const getAdditionalList = async () => {

    let response = null;
    let error = false;
    let data = [];
    let additionalList = [];

    try {
        response = await axios({
            url: `${url}/list`,
            method: "GET",
            headers: {
                token: UserMiddleware.getTokenStatic()
            },
        });
        data = response.data;
        if (response.error) {
            messageError(response.message)
        }
        if (data.error) {
            messageError("Error en el servidor, revisar api")
        }
        additionalList = data.additionalList

    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,
        additionalList
    });

}

const additionalSave = async (additional) => {
    
    let response = null;
    let error = false;
    let data = [];

    try {
        response = await axios({
            url: `${url}/save`,
            method: "POST",
            headers: {
                token: UserMiddleware.getToken()
            },
            data: {
                additional
            }
        });
        data = response.data;
        if (response.error) {
            messageError(response.message)
        }
        if (data.error) {
            messageError("Error en el servidor, revisar api")
        }

    } catch (dataError) {
        error = true;
        messageError(dataError)
    }

    return ({
        error,
        data
    });

}



const AdditionalServices = {
    getAdditionalStructure,
    additionalSave,
    getAdditionalList,
}

export default AdditionalServices;