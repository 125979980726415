

const focus = (IdComponent) => {
    document.getElementById(IdComponent).focus();
}

const ComponentTool ={
    focus
}

export default ComponentTool;